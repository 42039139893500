<template>
<div style="width:100%">
<v-row>
    <v-col>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="logs_cierre.afavor_id" :rules="[rules.requerido]" item-text="descripcion" item-value="id" :items="items.items_favorabilidad" :color="colores.secundario" label='* FAVOR A' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="logs_cierre.marcacion_id" item-text="descripcion" item-value="id" :items="items.items_marcacion" :color="colores.secundario" label='* MARCACIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="logs_cierre.roportunidad" :rules="[rules.requerido]" label='* RESPONSABLE OPORTUNIDAD' :color="colores.secundario"  autocomplete="off"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="logs_cierre.rcausal" :rules="[rules.requerido]" label='* RESPONSABLE CAUSA' :color="colores.secundario"  autocomplete="off"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-textarea v-model="logs_cierre.posiblescausas" :rules="[rules.requerido]" label='* POSIBLES CAUSAS' :color="colores.secundario" rows="3"></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea v-model="logs_cierre.accionestomar" :rules="[rules.requerido]" label='* ACCIONES A TOMAR' :color="colores.secundario" rows="3"></v-textarea>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-textarea v-model="logs_cierre.sinopsis" :rules="[rules.requerido]" label='* SINOPSIS' :color="colores.secundario" rows="3"></v-textarea>
        </v-col>
      </v-row>
    </v-col>
</v-row>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'lectura_form_cierreComponent',
  components: {

  },
  mixins: [recFunciones],
  props: ['colores', 'logs_cierre', 'rules', 'items'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }
}
</script>
