<template>
<div style="width:100%">
<v-container fluid class="pa-8">
<v-card>
  <v-card-text>
    <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-text-field v-model="dato" :color="colores.primario" label="CODIGO DE LA PQR"></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn @click="rec_gestion_buscar(11, dato)" :loading="loadingBusqueda" elevation="2"><v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
    </v-row>
    <v-card-text>
      <div  v-for="(item, index) in items_registros" :key="index">
        <listado :session="session" :rules="rules" :items="item" :colores="colores" :item="item" @select_pqr="selecion_pqr"></listado>
      </div>
    </v-card-text>
  </v-card-text>
</v-card>

<br>
<mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</v-container>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recGestion from '@/js/rec_gestion.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'anularComponent',
  components: {
    listado: () => import('@/components/anular/listadoComponent.vue'),
    mensajesSave
  },
  props: ['session', 'colores', 'rules'],
  mixins: [recFunciones, recGestion],
  data: () => ({
    loadingBusqueda: false,
    dato: '',
    items_registros: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: [],
    gestion: null

  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    selecion_pqr (item) {
      console.log('alex')
      console.log(item)
      this.gestion = item
      this.dialogo.pregunta_anular = true
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA_ANULAR') {
        this.rec_gestion_anular_id(this.gestion.aplicativo_id, this.gestion.retroalimentacion_id, this.gestion.id)
      } else if (newvalue === 'CANCELAR_PREGUNTA_ANULAR') this.dialogo.pregunta_anular = false
    }

  } // FIN DE METODOS
}
</script>
