<template>
    <div style="width:100%">
        <v-dialog v-model="dialog" width="100%" persistent>
            <v-card>
                <v-toolbar dense :color="colores.primario" dark>
                    <span style="font-size:20px"><b>EDICION/DETALLES QUEJA O RECLAMO</b></span>
                    <v-spacer></v-spacer>
                    <v-btn @click="cerrarVenta" icon color="white">
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-2">
                  <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="gestion.instancia_id"  :loading="loadingForm" :items="items.items_instancia_recepcion" item-text="descripcion" item-value="id" :color="colores.secundario" label='INSTANCIA DE RECEPCIÓN' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'QUEJA EXPRESS'" :obj="registro.quejaexpress"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="12" class="pr-1">
            <texto3 :colores="colores" :titulo="'TUTELA'" :obj="registro.tutela"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'ENTE DE CONTROL'" :obj="registro.entecontrol"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="gestion.desistimiento_id" clearable :loading="loadingForm" :items="items.items_desistimientos" item-text="descripcion" item-value="id" :color="colores.secundario" label='DESISTIMIENTO' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="gestion.admision_id"  :loading="loadingForm" :items="items.items_admision" item-text="descripcion" item-value="id" :color="colores.secundario" label='ADMISIÓN' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="gestion.productodigital_id"  :loading="loadingForm" :items="items.items_producto_digital" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRODUCTO DIGITAL' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="gestion.aceptacion_id"  :loading="loadingForm" :items="items.items_aceptacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='ACEPTACIÓN' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="gestion.rectificacion_id"  :loading="loadingForm" :items="items.items_rectificacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='RECTIFICACÓN' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete  v-model="gestion.prorroga_id"  :loading="loadingForm" :items="items.items_prorroga" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRÓRROGA' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="gestion.marcacion_id" clearable :loading="loadingForm" :items="items.items_marcacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='MARCACIÓN' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="gestion.replica_id" v-on:input="input_replica(gestion.replica_id)" :loading="loadingForm" :items="items.items_replica" item-text="descripcion" item-value="id" :color="colores.secundario" label='RÉPLICA' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="gestion.argumento_replica" label='ARGUMENTO RÉPLICA' autocomplete="off" :color="colores.secundario" maxlength="256" counter="256"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
              <v-col md="12" cols="12">
                <v-btn @click="actualizarDatosPqr" :loading="this.loadingForm" block elevation="10">Guardar</v-btn>
              </v-col>
            </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recGestion from '@/js/rec_gestion.js'
import texto3 from '@/components/widgets/text3Component.vue'
export default {
  name: 'edicion_pqrComponent.vue',
  components: {
    texto3
  },
  mixins: [recFunciones, recGestion],
  props: ['colores', 'rules', 'dialog', 'registro', 'items'],
  data: () => ({
    loadingForm: false,
    gestion: { id: 0, replica_id: null, argumento_replica: null, desistimiento_id: null, instancia_id: null, admision_id: null, productodigital_id: null, aceptacion_id: null, rectificacion_id: null, prorroga_id: null, marcacion_id: null }
  }),
  created () {
    this.llenarDatos()
  },
  methods: {
    cerrarVenta () {
      this.$emit('closed_pqr')
    },
    actualizarDatosPqr () {
      this.rec_update_queja_reclamo(this.gestion, this.registro)
    },
    llenarDatos () {
      this.gestion.id = this.registro.id
      this.gestion.instancia_id = this.registro.instancia_id
      this.gestion.replica_id = this.registro.replica_id
      this.gestion.argumento_replica = this.registro.argumento_replica
      this.gestion.desistimiento_id = this.registro.desistimiento_id
      this.gestion.admision_id = this.registro.admision_id
      this.gestion.productodigital_id = this.registro.productodigital_id
      this.gestion.aceptacion_id = this.registro.aceptacion_id
      this.gestion.rectificacion_id = this.registro.rectificacion_id
      this.gestion.prorroga_id = this.registro.prorroga_id
      this.gestion.marcacion_id = this.registro.marcacion_id
    },
    input_replica () {
      this.gestion.argumento_replica = ''
    },
    actualizarQR () {
      this.$emit('actualizar_QR')
    }
  }
}
</script>
