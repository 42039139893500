<template>
  <div style='width:100%'>
  </diV>
</template>
<script>
export default {
  name: 'LoginRecuperar',
  data: () => ({
  }),
  created () {
  }, // FIN DE CREATED
  watch: {
  }, // FIN DE WATCH
  methods: {

  } // FIN DE METODOS
}
</script>
