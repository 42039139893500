<template>
<v-app id="inspire" style="background:#FAFAFA">
  <v-main>
    <router-view></router-view>
  </v-main>
</v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
  })
}
</script>
