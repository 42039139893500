import axios from 'axios'
export default {
  methods: {
    rec_departamento_por_id (pDepartamentoId) {
      this.loadingCiudad = true
      const urlroute = this.$store.getters.getUrl + 'ciudades_departamento/' + pDepartamentoId
      const data = { contacto: this.contacto, gestion: this.gestion }
      this.items_ciudades = []
      axios({
        url: urlroute,
        method: 'GET',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_ciudades = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingCiudad = false
      })
    }

  }
}
