<template>
<div style="width:100%">
  <v-container fluid v-if="loadingRegistro">
    <br>
    <center><v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate></v-progress-circular></center>
  </v-container>
  <v-container fluid v-else>
    <lecturaHeader :session="session" :rules="rules" :items="items" :colores="colores" :registro="registro"  @refrescar_pqr="update_QR"></lecturaHeader>
    <br>
    <lecturaAsegurado :session="session" :rules="rules" :items="items" :colores="colores" :gescontacto="registro.gescontacto" :registro="registro" @refrescar_pqr="update_QR"></lecturaAsegurado>
    <br>
    <lecturaPqr :prorroga_id="prorroga_id" :session="session" :rules="rules" :items="items"  @refrescar_pqr="update_QR" v-if="registro.retroalimentacion.id === 29 || registro.retroalimentacion.id === 31" :colores="colores" :registro="registro" ></lecturaPqr>
    <br>
    <v-card>
      <v-card-text>
        <v-row no-gutters>
              <v-col cols="12">
                <v-card-text style="height: 500px; white-space:pre-wrap; overflow-y:auto; overflow-x: visible;">
                  {{ registro.descripcion }}
                </v-card-text>
              </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br>
    <v-card v-if="registro.subestado_id !== 4 && session.area[0].area_id !== 15">
      <v-card-text>
        <v-form ref="form">
          <v-row no-gutters v-if="registro.gesadicional">
            <v-col cols="12" md="6" class="pr-1">
              <texto2 :colores="colores" :titulo="'PRORROGA'" :descripcion="registro.gesadicional.prorroga.descripcion"></texto2>
            </v-col>
            <v-col cols="12" md="6" class="pr-1">
              <texto2 :colores="colores" :titulo="'FECHA DE CIERRE'" :descripcion="registro.gesadicional.fecha"></texto2>
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col cols="12" md="6" class="pr-1" v-if="logs.subestado_id!==4">
              <v-autocomplete  v-model="prorroga_id" :items="items.items_prorroga" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRÓRROGA' autocomplete="off"></v-autocomplete>
            </v-col>
          </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="logs.descripcion" :rules="[rules.requerido]" label='* DESCRIPCION' :color="colores.secundario" rows="7"></v-textarea>
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters>
              <v-col cols="12">
                <uploadFile :titulo="'ADJUNTAR ARCHIVOS'" :session="session" :keyform="logs.keyform" :items_archivos="items_archivos" :urlroute="'upload_cargar_editar'" :tipo="'LOG'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFile>
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete v-model="logs.subestado_id" v-on:input="input_subestado(logs.subestado_id)" :rules="[rules.requerido]" :items="items.items_subestadoslogs" item-text="descripcion" item-value="id" :color="colores.secundario" label="* ESTADO" autocomplete="off"></v-autocomplete>
              </v-col>
            </v-row>

            <escalamientosForm v-if="logs.subestado_id === 2" :objs="objs" :colores="colores" :rules="rules" :items="items"></escalamientosForm>

            <v-row v-if="logs.subestado_id===3">
              <v-col>
                <v-text-field v-model="logs_cliente.correo" :rules="[rules.requerido, rules.email]" maxlength="100" counter="100" :color="colores.primario" label="CORREO ELECTRONICO *"></v-text-field>
              </v-col>
            </v-row>

            <lecturaFormCierre v-if="(registro.retroalimentacion_id === 29 || registro.retroalimentacion_id === 31) && logs.subestado_id === 4" :colores="colores" :items="items" :logs_cierre="logs_cierre" :rules="rules"></lecturaFormCierre>
            <br>
            <v-row no-gutters v-if="(registro.retroalimentacion_id === 29 || registro.retroalimentacion_id === 31) && logs.subestado_id === 4">
              <v-col cols="5" >
                <uploadFile :titulo="'ADJUNTAR ARCHIVOS FINAL'" :tipoAnexo="1" :session="session" :keyform="logs.keyform" :items_archivos="items_archivossfc" :urlroute="'upload_cargar_cierre'" :tipo="'CIERRE'" :colores="colores" @refresh_archivos="refrescar_archivossfc"></uploadFile>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="5">
                <uploadFile :titulo="'ADJUNTAR ARCHIVOS ANEXO'" :tipoAnexo="2"  :session="session" :keyform="logs.keyform" :items_archivos="items_archivossfc" :urlroute="'upload_cargar_cierre'" :tipo="'CIERRE'" :colores="colores" @refresh_archivos="refrescar_archivossfc"></uploadFile>
              </v-col>
            </v-row>
            <span class="red--text text-h3">{{ erroresfiles }}</span>
            <br><br>
            <v-row>
              <v-col cols="12">
                <v-btn rounded @click="validar" block dark>GUARDAR</v-btn>
              </v-col>
            </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-toolbar v-if="registro.subestado_id===4" :color="colores.secundario" dense dark><span style="font-size:20px"><b>CERRADO</b></span></v-toolbar>
      <v-card-text v-if="registro.subestado_id===4">
          <v-row>
            <v-col cols="12" md="6">
              <texto2 :colores="colores" :titulo="'USUARIO CERRO'" :descripcion="registro.usuariocerro.nombre_usuario + ' ' + registro.usuariocerro.apellido_usuario"></texto2>
            </v-col>
            <v-col cols="12" md="6">
              <texto2 :colores="colores" :titulo="'FECHA CERRO'" :descripcion="registro.fechacerrado"></texto2>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <lecturaCierre v-if="registro.gescierre" :colores="colores" :gescierre="registro.gescierre"></lecturaCierre>
          <br>
          <center><span class="text-h6">ARCHIVOS SFC</span></center>
          <v-list dense>
              <div v-for="(archivo, i) in registro.gescierrefiles" :key="i">
                <verArchivos :colores="colores" :tipo="'CIERRE'" :item="archivo"></verArchivos>
                <v-divider></v-divider>
              </div>
          </v-list>
      </v-card-text>
    </v-card>
    <br>
    <lecturaReplicas :colores="colores" :registro="registro"></lecturaReplicas>
    <br>
    <lecturaLogs :colores="colores" :registro="registro"></lecturaLogs>
    <br><br><br>
    <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  </v-container>
</div>
</template>

<script>
import recGestion from '@/js/rec_gestion.js'
import recLog from '@/js/rec_logs.js'
import recFunciones from '@/js/rec_funciones.js'
import lecturaHeader from '@/components/inbound/lectura_headerComponent.vue'
import lecturaAsegurado from '@/components/inbound/lectura_aseguradoComponent.vue'
import lecturaPqr from '@/components/inbound/lectura_pqrComponent.vue'
import lecturaLogs from '@/components/inbound/lectura_logsComponent.vue'
import lecturaCierre from '@/components/inbound/lectura_cierreComponent.vue'
import lecturaFormCierre from '@/components/inbound/lectura_form_cierreComponent.vue'
import escalamientosForm from '@/components/inbound/form_escalamientosComponent.vue'
import texto2 from '@/components/widgets/text2Component.vue'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
import uploadFile from '@/components/widgets/uploadComponent.vue'
import verArchivos from '@/components/widgets/ver_archivosComponent.vue'
import lecturaReplicas from '@/components/inbound/lectura_replicasComponent.vue'

export default {
  name: 'lecturaComponent',
  components: {
    lecturaHeader,
    lecturaAsegurado,
    lecturaPqr,
    lecturaLogs,
    lecturaCierre,
    escalamientosForm,
    texto2,
    lecturaFormCierre,
    uploadFile,
    verArchivos,
    mensajesSave,
    lecturaReplicas
  },
  mixins: [recFunciones, recGestion, recLog],
  props: ['session', 'colores', 'token', 'registro_id', 'rules', 'items'],
  data: () => ({
    prorroga_id: 251,
    loadingRegistro: false,
    erroresfiles: '',
    objs: { objArea: null, logs_escalamientos: [], errores: '' },
    registro: null,
    logs: { keyform: 0, id_usuario: 0, gestion_id: 0, subestado_id: 0, descripcion: '' },
    logs_cliente: { geslog_id: 0, correo: '', iscorreoenviado: 0 },
    logs_cierre: { gestion_id: 0, afavor_id: null, roportunidad: '', rcausal: '', posiblescausas: '', accionestomar: '', sinopsis: '', marcacion_id: null },
    items_archivos: [],
    items_archivossfc: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  watch: {
    token: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_log_inicializar_log()
        this.rec_log_inicializar_cliente()
        this.rec_log_inicializar_cierre()
        this.rec_gestion_id(this.registro_id)
        this.prorroga_id = 251
      }
    }
  },
  created () {
  },
  methods: {
    refrescar_archivossfc (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivossfc.push(data[i])
      }
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    input_subestado (pSubestadoId) {
      this.logs_cierre.gestion_id = 0
      this.logs_cierre.afavor_id = null
      this.logs_cierre.marcacion_id = null
      this.logs_cierre.roportunidad = ''
      this.logs_cierre.rcausal = ''
      this.logs_cierre.posiblescausas = ''
      this.logs_cierre.accionestomar = ''
      this.logs_cierresinopsis = ''
      if (pSubestadoId === 4) {
        this.prorroga_id = 251
      }
    },
    validar () {
      this.objs.errores = ''
      this.erroresfiles = ''
      if (this.$refs.form.validate()) {
        if (this.logs.subestado_id === 2 && this.objs.logs_escalamientos.length <= 0) {
          this.objs.errores = 'DEBE AGREGAR UN ESCALAMIENTO'
          this.dialogo.incompleto = true
        } else if (this.registro.retroalimentacion_id === 29 || this.registro.retroalimentacion_id === 31) {
          if (this.logs.subestado_id === 4) {
            let cantidad = 0
            for (let i = 0; i < this.items_archivossfc.length; i++) {
              if (this.items_archivossfc[i].estado_id === 1) {
                cantidad = cantidad + 1
              }
            }
            if (cantidad <= 0) {
              this.erroresfiles = 'DEBE INGRESAR EL DOCUMENTO DE RESPUESTA DEL SFC'
              this.dialogo.incompleto = true
            } else if (!this.rec_function_totaSize(this.items_archivossfc, 30000000)) {
              // 20000000 MB
              this.erroresfiles = 'ERROR - LOS ARCHIVOS EXCEDE LOS 30 MB'
              this.dialogo.incompleto = true
            } else {
              this.dialogo.pregunta = true
            }
          } else {
            this.dialogo.pregunta = true
          }
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_log_nuevo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    update_QR () {
      this.rec_gestion_id(this.registro_id)
    }

  }
}
</script>
