<template>
  <div style="width:100%">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" md="10" class="pr-1">
          <v-row no-gutters>
            <v-col cols="12" md="5" class="pr-1">
              <v-autocomplete v-if="items.items_areas.length > 0 " v-model="seleccionAsesor" multiple label="ASESOR" :items="items.items_areas[10].asesores" item-text="usuario.nombre_usuario" item-value="id_usuario"></v-autocomplete>
            </v-col>
            <v-col  cols="12" md="2" class="pr-1">
              <v-text-field v-if="this.seleccionAsesor.length > 0" v-model="seleccionAnio" label="AÑO" type="number"></v-text-field></v-col>
            <v-col cols="12" md="5" class="pr-1">
              <v-autocomplete v-if="this.seleccionAsesor.length > 0" v-model="seleccionMes" multiple label="MES" :items="mes" item-text="descripcion" item-value="id"></v-autocomplete></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="2">
              <v-switch v-if="this.seleccionAsesor.length > 0"  v-model="filtrarEstado" label="Filtrar estado"  @change="changeEstado(filtrarEstado)"></v-switch>
            </v-col>
            <v-col cols="12" md="10">
                <v-autocomplete v-if="filtrarEstado"  v-model="seleccionEstado" multiple label="ESTADO" :items="items.items_subestados" item-text="descripcion" item-value="id"></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="2">
          <v-row>
            <v-col cols="12" md="4" >
              <v-tooltip v-if="this.seleccionAsesor.length > 0"  bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="objs.loading" block v-bind="attrs" v-on="on" @click="actualizar_pqrs"><v-icon>mdi-cloud-search-outline</v-icon> </v-btn>
                </template>
                <span>Filtrar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="refrescar" :loading="loadingForm" block v-bind="attrs" v-on="on"> <v-icon>mdi-refresh</v-icon> </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="4">
              <v-tooltip bottom v-if="session.area[0].area_id !== 15">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block v-bind="attrs" v-on="on" @click="nuevo_pqr"><v-icon>mdi-file-outline</v-icon> </v-btn>
                </template>
                <span>Crear PQR</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <br>
      <bandejegaListado :rules="rules" :session="session" :colores="colores" :items="items" :items_escalamientos="objGlobal.items_escalamientos"></bandejegaListado>
    </v-container>
  </div>
</template>

<script>
import bandejegaListado from '@/components/bandejas/bandeja_pqrs_listadoComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
export default {
  name: 'bandeja_pqrsComponent',
  components: {
    bandejegaListado
  },
  mixins: [recBandeja],
  props: ['session', 'colores', 'rules', 'items', 'loadingForm', 'objGlobal'],
  data: () => ({
    objs: { loading: false },
    filtrarEstado: false,
    seleccionAsesor: [],
    seleccionAnio: 0,
    seleccionMes: [],
    seleccionEstado: [],
    mes: [{ id: 1, descripcion: 'Enero' }, { id: 2, descripcion: 'Febrero' }, { id: 3, descripcion: 'Marzo' }, { id: 4, descripcion: 'Abril' }, { id: 5, descripcion: 'Mayo' }, { id: 6, descripcion: 'Junio' }, { id: 7, descripcion: 'Julio' }, { id: 8, descripcion: 'Agosto' }, { id: 9, descripcion: 'Septiembre' }, { id: 10, descripcion: 'Octubre' }, { id: 11, descripcion: 'Noviembre' }, { id: 12, descripcion: 'Diciembre' }]
  }),
  created () {
    this.fechaActual()
  },
  methods: {
    nuevo_pqr () {
      this.$emit('new_inbound')
    },
    refrescar () {
      this.objGlobal.isFiltro = false
      this.$emit('refresh_pqrs')
    },
    fechaActual () {
      const currentTime = new Date()
      this.seleccionAnio = currentTime.getFullYear()
      this.seleccionMes.push(currentTime.getMonth() + 1)
    },
    actualizar_pqrs () {
      this.objGlobal.isFiltro = true
      this.rec_bandeja_escalamientos_filtro(this.seleccionAsesor, this.seleccionAnio, this.seleccionMes, this.seleccionEstado, this.filtrarEstado)
    },
    changeEstado (pEstado) {
      if (pEstado === false) {
        this.seleccionEstado = []
      }
    }
  }
}
</script>
