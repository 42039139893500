import axios from 'axios'
export default {
  methods: {
    rec_estadisticas_anio (anio) {
      this.showAnual = true
      this.showMes = false
      this.loading = true
      this.objEstadistica = null
      var urlroute = this.$store.getters.getUrl + 'estadisticas_anual/' + anio
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.objEstadistica = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loading = false
      })
    },
    rec_estadisticas_mes (anio, mes) {
      this.showMes = true
      this.loadingMes = true
      this.objMes = null
      var urlroute = this.$store.getters.getUrl + 'estadisticas_mes/' + anio + '/' + mes
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.objMes = response.data
        console.log(this.objMes)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMes = false
      })
    },
    rec_estadisticas_reporte1 (pAnio, pMes) {
      var urlroute = this.$store.getters.getUrl + 'estadisticas_reporte1/' + pAnio + '/' + pMes
      const data = { anio: pAnio, mes: pMes }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        console.log(response.data)
        window.open(this.$store.getters.getUrlFile + response.data.archivo, '_blank')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }
  }
}
