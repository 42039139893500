<template>
<div style="width:100%">
<v-row no-gutters>
  <v-col cols="12" md="6" class="pr-1">
    <v-text-field solo  background-color="red" maxlength="30" counter="30"></v-text-field>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field solo  background-color="blue" maxlength="30" counter="30"></v-text-field>
  </v-col>
</v-row>
<v-row no-gutters>
  <v-col cols="12" md="6" class="pr-1">
    <v-text-field solo  background-color="teal" maxlength="30" counter="30"></v-text-field>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field solo  background-color="cyan" maxlength="30" counter="30"></v-text-field>
  </v-col>
</v-row>
<v-row no-gutters>
  <v-col cols="12" md="6" class="pr-1">
    <v-text-field solo  background-color="orange" maxlength="30" counter="30"></v-text-field>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field solo  background-color="blue-grey" maxlength="30" counter="30"></v-text-field>
  </v-col>
</v-row>
<v-row no-gutters>
  <v-col cols="12" md="6" class="pr-1">
    <v-text-field solo  background-color="brown" maxlength="30" counter="30"></v-text-field>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field solo  background-color="purple" maxlength="30" counter="30"></v-text-field>
  </v-col>
</v-row>
<v-row no-gutters>
  <v-col cols="12" md="6" class="pr-1">
    <v-text-field solo  background-color="lime" maxlength="30" counter="30"></v-text-field>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field solo  background-color="pink" maxlength="30" counter="30"></v-text-field>
  </v-col>
</v-row>

</div>
</template>

<script>

export default {
  name: 'bandejaConfiguracionComponent',
  components: {

  },
  data: () => ({

  })
}
</script>
