import axios from 'axios'
export default {
  methods: {
    rec_gestion_buscar (pTipoId, pDato) {
      this.loadingBusqueda = true
      this.registro = null
      const urlroute = this.$store.getters.getUrl + 'gestion_buscar/' + pTipoId + '/' + pDato
      this.items_registros = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.items_registros = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_id (pId) {
      this.loadingRegistro = true
      this.registro = null
      const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        // console.log(response)
        this.registro = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingRegistro = false
      })
    },
    rec_gestion_datos () {
      this.gestion.descripcion = this.rec_funciones_mayuscula(this.gestion.descripcion)
      this.gestion.id_usuario = this.session.id
      this.gestion.subestado_id = 1
      this.gestion.segundos = this.tiempo

      this.contacto.nombres = this.rec_funciones_mayuscula(this.contacto.nombres)
      this.contacto.apellido1 = this.rec_funciones_mayuscula(this.contacto.apellido1)
      this.contacto.apellido2 = this.rec_funciones_mayuscula(this.contacto.apellido2)
      this.contacto.empresa = this.rec_funciones_mayuscula(this.contacto.empresa)
      this.contacto.cargo = this.rec_funciones_mayuscula(this.contacto.cargo)
    },
    rec_gestion_nuevo () {
      this.rec_gestion_datos()

      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion'
      const data = { contacto: this.contacto, gestion: this.gestion, escalamientos: this.items_form.escalamientos, otros: this.otros }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {

          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.dialogo.detalle = true
        this.dialogo.objPayload = response.data
        this.rec_gestion_inicializar_contacto()
        this.rec_gestion_inicializar_pqr()
        this.gestion.keyform = this.rec_funciones_key()
        this.$refs.form.resetValidation()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_inicializar_contacto () {
      this.contacto.gestion_id = 0
      this.contacto.documento_id = 0
      this.contacto.identificacion = ''
      this.contacto.nombres = ''
      this.contacto.apellido1 = ''
      this.contacto.apellido2 = ''
      this.contacto.empresa = ''
      this.contacto.cargo = ''
      this.contacto.telefono = ''
      this.contacto.celular = ''
      this.contacto.departamento_id = null
      this.contacto.ciudad_id = null
      this.contacto.correo = ''
      this.contacto.pais_id = 156
      this.contacto.direccion = null
      this.contacto.tipopersona_id = null
      this.contacto.sexo_id = null
      this.contacto.lgbtiq_id = 251
      this.contacto.especial_id = 271
    },
    rec_gestion_inicializar_pqr () {
      this.items_archivos = []
      this.gestion.keyform = this.rec_funciones_key()
      this.objs.retroalimentacion = null
      this.gestion.aplicativo_id = 319
      this.gestion.canal_id = 0
      this.gestion.retroalimentacion_id = 0
      this.gestion.tipologia_id = 0
      this.gestion.sucursal_id = 0
      this.gestion.proceso_id = 0
      this.gestion.ramo_id = 0
      this.gestion.descripcion = ''
      this.gestion.subestado_id = 0
      this.gestion.segundos = 0
      this.gestion.id_usuariocerro = 0
      this.gestion.fechacerrado = null
      this.gestion.fechaparacerrar = null
      this.gestion.dias = 0
      this.gestion.codigo_sfc = ''
      this.gestion.tutela_id = null
      this.gestion.entecontrol_id = null
      this.gestion.replica_id = null
      this.gestion.argumento_replica = null
      this.gestion.desistimiento_id = null
      this.gestion.quejaexpress_id = null
      this.gestion.instancia_id = null
      this.gestion.ptorecepcion_id = null
      this.gestion.admision_id = null
      this.gestion.productodigital_id = null
      this.gestion.aceptacion_id = null
      this.gestion.rectificacion_id = null
      this.gestion.prorroga_id = null
      this.gestion.marcacion_id = null
    },
    rec_update_datos_del_requerimiento (ptoRecepcion, sucursal, ramo, proceso, gestion, tipologia, retroalimentacionId, pRegistro) {
      const urlroute = this.$store.getters.getUrl + 'update_datos_requerimiento'
      this.loadingForm = true
      const data = { registro: pRegistro, ptoRecepcion: ptoRecepcion, sucursal: sucursal, ramo: ramo, proceso: proceso, gestion: gestion, tipologia: tipologia, retroalimentacionId: retroalimentacionId }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.actualizarQR()
        this.loadingForm = false
        this.cerrarVenta()
      })
    },
    rec_update_asegurado (contacto) {
      const urlroute = this.$store.getters.getUrl + 'update_asegurado/'
      this.loadingForm = true
      const data = contacto
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.actualizarQR()
        this.loadingForm = false
        this.cerrarVenta()
      })
    },
    rec_update_queja_reclamo (contacto) {
      const urlroute = this.$store.getters.getUrl + 'update_queja_reclamo/'
      this.loadingForm = true
      const data = contacto
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {

      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.actualizarQR()
        this.loadingForm = false
        this.cerrarVenta()
      })
    },
    rec_gestion_anular_id (aplicativoId, retroalimentacionId, pId) {
      this.dialogo.esperar = true
      const urlroute = this.$store.getters.getUrl + 'gestion_anular/' + aplicativoId + '/' + retroalimentacionId + '/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        console.log(response)
        this.items_registros = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.pregunta_anular = false
        this.dialogo.barfinalizoanulo = true
        this.dialogo.esperar = false
      })
    }

  }
}
