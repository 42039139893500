<template>
<div style="width:100%">
  <v-dialog v-model="dialogSfc" fullscreen persistent transition="dialog-bottom-transition">
    <v-card :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>PQR</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <formSfc :session="session" :items="items" :rules="rules" :colores="colores" :token="token" :registro_id="registro_id"></formSfc>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import formSfc from '@/components/sfc/formComponent.vue'
export default {
  name: 'lectura_dialogoComponent',
  components: {
    formSfc
  },
  props: ['session', 'colores', 'rules', 'dialogSfc', 'token', 'registro_id', 'items'],
  data: () => ({
  }),
  methods: {
    cerrarVenta () {
      this.$emit('closed_ventana_sfc')
    }
  }
}
</script>
