<template>
<div style="width:100%">
<v-row>
  <v-col>
    <center><h3> REPORTES </h3></center>
  </v-col>
</v-row>
<v-row>
  <v-col>
    <reportesComponent :anio="anio" :mes="selMes" :session="session" :colores="colores"></reportesComponent>
  </v-col>
</v-row>

<v-card-text>
  <v-row>
    <v-col>
      <center><h3> ESCALAMIENTOS </h3></center>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="5"></v-col>
    <v-col cols="12" md="1" class="text-center"><span style="font-size: 10px;">TOTAL</span></v-col>
    <v-col cols="12" md="2" class="text-center"><span style="font-size: 10px;">ESCALAMIENTOS</span></v-col>
    <v-col cols="12" md="1" class="text-center"><span style="font-size: 10px;">0 - 3 DIAS</span></v-col>
    <v-col cols="12" md="1" class="text-center"><span style="font-size: 10px;">4 - 6 DIAS</span></v-col>
    <v-col cols="12" md="1" class="text-center"><span style="font-size: 10px;">MAS DE 7 DIAS</span></v-col>
  </v-row>
  <div v-for="(escalamiento, index) in objMes.escalamintos" :key="index">
  <v-row>
    <v-col cols="12" md="5"> <span style="font-size: 10px;">{{ escalamiento.escaladoa.nombre_usuario + ' ' + escalamiento.escaladoa.apellido_usuario }}</span></v-col>
    <v-col cols="12" md="1" class="text-center">
      <v-card color="green" dark><span style="font-size: 10px;"><center>{{ escalamiento.cantidad }}</center></span></v-card>
    </v-col>
    <v-col cols="12" md="2" class="text-center">
      <v-card color="pink" dark><span style="font-size: 10px;"><center>{{ total_escalados(escalamiento) }}</center></span></v-card>
    </v-col>
    <v-col cols="12" md="1" class="text-center">
      <v-card v-if="dias_tipo1(escalamiento)>0" color="blue" dark><span style="font-size: 10px;"><center>{{ dias_tipo1(escalamiento) }}</center></span></v-card>
    </v-col>
    <v-col cols="12" md="1" class="text-center">
      <v-card v-if="dias_tipo2(escalamiento)>0" color="amber" dark><span style="font-size: 10px;"><center>{{ dias_tipo2(escalamiento) }}</center></span></v-card>
    </v-col>
    <v-col cols="12" md="1" class="text-center">
      <v-card v-if="dias_tipo3(escalamiento)>0" color="red" dark><span style="font-size: 10px;"><center>{{ dias_tipo3(escalamiento) }}</center></span></v-card>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  </div>
</v-card-text>
<v-card-text>
  <v-row>
    <v-col>
        <center><h3> TIPIFICACIONES </h3></center>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <div v-for="(tipo, index) in objMes.tipos" :key="index">
        <v-row>
          <v-col cols="12" md="3">
            <span style="font-size: 10px;">{{ tipo.descripcion }}</span>
          </v-col>
          <v-col cols="12" md="9">
            <div v-for="(gestion, index) in objMes.gestiones" :key="index + 10000" >
              <v-row no-gutters v-if="tipo.id==gestion.retroalimentacion_id">
                <v-col cols="12" md="8">
                  <span v-if="gestion.tipologia" style="font-size: 10px;"><b>{{ gestion.tipologia.descripcion }}</b></span>
                </v-col>
                <v-col cols="12" md="2">
                  <v-card v-if="tipificaciones_estados(gestion.retroalimentacion_id, gestion.tipologia_id, 2)>0" color="pink" dark><span style="font-size: 10px;"><center>{{ tipificaciones_estados(gestion.retroalimentacion_id, gestion.tipologia_id, 2) }}</center></span></v-card>
                </v-col>
                <v-col cols="12" md="2">
                  <v-card v-if="tipificaciones_estados(gestion.retroalimentacion_id, gestion.tipologia_id, 1)>0" color="green" dark><span style="font-size: 10px;"><center>{{ tipificaciones_estados(gestion.retroalimentacion_id, gestion.tipologia_id, 1) }}</center></span></v-card>
                </v-col>
              </v-row>
              <v-divider v-if="tipo.id==gestion.retroalimentacion_id"></v-divider>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
    </v-col>
  </v-row>
</v-card-text>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'
import reportesComponent from '@/components/estadisticas/reportesComponent.vue'
export default {
  name: 'anualComponent',
  components: {
    reportesComponent
  },
  props: ['anio', 'selMes', 'session', 'colores', 'objMes'],
  mixins: [recFunciones, recEstadisticas],
  data: () => ({

  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    tipificaciones_estados (pretroalimentacionId, ptipologiaId, pestadoId) {
      const estados = this.objMes.estados
      let total = 0
      for (let i = 0; i < estados.length; i++) {
        if (pretroalimentacionId === estados[i].retroalimentacion_id && ptipologiaId === estados[i].tipologia_id) {
          if (pestadoId === 1 && estados[i].subestado_id === 4) {
            total = total + estados[i].cantidad
          } else if (pestadoId === 2 & estados[i].subestado_id !== 4) {
            total = total + estados[i].cantidad
          }
        }
      }
      return total
    },
    total_escalados (escalaminto) {
      const tiempos = this.objMes.tiempos
      let total = 0
      for (let i = 0; i < tiempos.length; i++) {
        if (escalaminto.id_escaladoa === tiempos[i].id_escaladoa) {
          total = total + tiempos[i].cantidad
        }
      }
      return total
    },
    dias_tipo1 (escalaminto) {
      const tiempos = this.objMes.tiempos
      let total = 0
      for (let i = 0; i < tiempos.length; i++) {
        if (escalaminto.id_escaladoa === tiempos[i].id_escaladoa && tiempos[i].dias <= 3) {
          total = total + tiempos[i].cantidad
        }
      }
      return total
    },
    dias_tipo2 (escalaminto) {
      const tiempos = this.objMes.tiempos
      let total = 0
      for (let i = 0; i < tiempos.length; i++) {
        if (escalaminto.id_escaladoa === tiempos[i].id_escaladoa && (tiempos[i].dias >= 4 && tiempos[i].dias <= 6)) {
          total = total + tiempos[i].cantidad
        }
      }
      return total
    },
    dias_tipo3 (escalaminto) {
      const tiempos = this.objMes.tiempos
      let total = 0
      for (let i = 0; i < tiempos.length; i++) {
        if (escalaminto.id_escaladoa === tiempos[i].id_escaladoa && (tiempos[i].dias >= 7)) {
          total = total + tiempos[i].cantidad
        }
      }
      return total
    }

  } // FIN DE METODOS
}
</script>
