<template>
<div style="width:100%">
  <v-card>
      <v-toolbar dense :color="colores.primario" dark>
      <span style="font-size:20px"><b>CONTACTO</b></span>
      <v-spacer></v-spacer>
      <v-btn v-if="session.area[0].area_id === 14 || session.area[0].area_id === 1  && registro.subestado_id !== 4 && registro.subestado_id !== 7" @click="openDialogEditAsegurado" icon color="white"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'CODIGO DE LA QUEJA'" :descripcion="registro.codigo"></texto2></v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'ESTADO'" :descripcion="registro.subestado.descripcion"></texto2>
          </v-col>
        </v-row>
           <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'IDENTIFICACION'" :descripcion="gescontacto.identificacion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'TIPO DE DOCUMENTO'" :obj="gescontacto.documento"></texto3>
          </v-col>
        </v-row>
           <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'NOMBRES'" :descripcion="gescontacto.nombres"></texto2>
          </v-col>
          <v-col cols="12" md="3" class="pr-1">
            <texto2 :colores="colores" :titulo="'PRIMER APELLIDO '" :descripcion="gescontacto.apellido1"></texto2>
          </v-col>
          <v-col cols="12" md="3">
            <texto2 :colores="colores" :titulo="'SEGUNDO APELLIDO'" :descripcion="gescontacto.apellido2"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'EMPRESA'" :descripcion="gescontacto.empresa"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'CARGO'" :descripcion="gescontacto.cargo"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'TELEFONO'" :descripcion="gescontacto.telefono"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'CELULAR'" :descripcion="gescontacto.celular"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'DIRECCIÓN'" :descripcion="gescontacto.direccion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'PAÍS'" :obj="gescontacto.pais"></texto3>
          </v-col>
        </v-row>
             <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'DEPARTAMENTO'" :obj="gescontacto.departamento"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'CIUDAD'" :obj="gescontacto.ciudad"></texto3>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'CORREO'" :descripcion="gescontacto.correo"></texto2>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto3 :colores="colores" :titulo="'TIPO DE PERSONA'" :obj="gescontacto.tipopersona"></texto3>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto3 :colores="colores" :titulo="'TIPO DE USUARIO'" :obj="gescontacto.tipousuario"></texto3>
          </v-col>
          <v-col cols="12" md="4">
            <texto3 :colores="colores" :titulo="'SEXO'" :obj="gescontacto.sexo"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'LGTBIQ+'" :obj="gescontacto.lgbtiq"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'CONDICIÓN ESPECIAL'" :obj="gescontacto.especial"></texto3>
          </v-col>
        </v-row>
      </v-card-text>
  </v-card>
  <edicionAsegurado :rules="rules" :items="items"  :gescontacto="gescontacto" :registro="registro" :colores="colores" :dialog="dialogAsegurado" @closed_asegurado="cerrar_asegurado"  @actualizar_QR="update_QR"></edicionAsegurado>
</div>
</template>

<script>
import texto2 from '@/components/widgets/text2Component.vue'
import texto3 from '@/components/widgets/text3Component.vue'
import edicionAsegurado from './edicion_aseguradoComponent.vue'

import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandejaOutboundComponent',
  components: {
    texto2,
    texto3,
    edicionAsegurado
  },
  mixins: [recFunciones],
  props: ['colores', 'gescontacto', 'registro', 'rules', 'items', 'session'],
  data: () => ({
    dialogAsegurado: false
  }),
  created () {

  },
  methods: {
    openDialogEditAsegurado () {
      this.dialogAsegurado = true
    },
    cerrar_asegurado () {
      this.dialogAsegurado = false
    },
    update_QR () {
      this.$emit('refrescar_pqr')
    }
  }
}
</script>
