import axios from 'axios'
export default {
  methods: {
    rec_bandeja_escalamientos (idUsuario) {
      const urlroute = this.$store.getters.getUrl + 'escalamientos_usuario/' + idUsuario
      this.loadingForm = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.objGlobal.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingForm = false
      })
    },
    rec_bandeja_escalamientos_filtro (idUsuario, pAnio, pMes, pEstado, pFiltrar) {
      const urlroute = this.$store.getters.getUrl + 'escalamientos_usuario_filtro/'
      const data = { usuario: idUsuario, anio: pAnio, mes: pMes, estado: pEstado, filtrar: pFiltrar }
      this.objs.loading = true
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // console.log(response.data)
        this.objGlobal.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.objs.loading = false
      })
    }
  }
}
