<template>
<div style="width:100%">
  <v-row>
    <v-col cols="12" md="6">
      <v-autocomplete v-model="objs.objArea" return-object item-value="id" item-text="descripcion" :items="items.items_areas" :color="colores.secundario" label='* AREAS' autocomplete="off"></v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-btn @click="agregar_escalamiento"> <v-icon>mdi-plus</v-icon></v-btn>
    </v-col>
  </v-row>
  <span class="red--text">{{ objs.errores }}</span>
  <v-row no-gutters v-if="objs.objArea">
    <template v-for="(item, index) in objs.objArea.asesores">
      <v-col cols="12" md="6" class="pt-1 pb-1" :key="index">
          <v-avatar size="30" :color="colores.primario" class="white--text">{{ index + 1 }}</v-avatar> <span class="pr-2"></span> {{ item.user.email }}
      </v-col>
    </template>
  </v-row>
  <br><br>
  <b v-if="objs.logs_escalamientos.length>0">ESCALAMIENTOS</b>
  <template v-for="(item, index) in objs.logs_escalamientos">
    <div :key="index" class="pt-2 pb-2">
      <v-card>
        <v-toolbar dense flat class="red--text">
          <b>{{ item.descripcion }}</b>
          <v-spacer></v-spacer>
          <v-btn @click="eliminar_escalamiento(index)" icon color="red"><v-icon>mdi-delete</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <template v-for="(itemAsesor, i) in item.asesores">
              <v-col cols="12" md="6" class="pt-1 pb-1" :key="i">
                <v-avatar size="30" :color="colores.black" class="white--text">{{ index + 1 }}</v-avatar> <span class="pr-2"></span> {{ itemAsesor.user.email }}
                <v-btn @click="eliminar_asesor_escalamiento(index, i)" icon color="red"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </template>
  <br><br>
</div>
</template>

<script>

export default {
  name: 'form_escalamientosComponent',
  components: {

  },
  props: ['colores', 'rules', 'objs', 'items'],
  data: () => ({
  }),
  created () {

  },
  methods: {
    agregar_escalamiento () {
      const area = Object.assign({}, this.objs.objArea)
      const id = area.id
      let bestado = 0
      for (let i = 0; i < this.objs.logs_escalamientos.length; i++) {
        if (id === this.objs.logs_escalamientos[i].id) {
          bestado = 1
        }
      }
      if (bestado === 0) {
        const descripcion = area.descripcion
        const asesores = area.asesores
        this.objs.logs_escalamientos.push({ id: id, descripcion: descripcion, asesores: asesores })
      }
    },
    eliminar_escalamiento (index) {
      this.objs.logs_escalamientos.splice(index, 1)
    },
    eliminar_asesor_escalamiento (index, i) {
      // console.log('index del objeto')
      // console.log(this.objs.logs_escalamientos[index])
      this.objs.logs_escalamientos[index].asesores.splice(i, 1)
    }

  }
}
</script>
