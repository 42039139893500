<template>
<div style="width:100%">
  <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>ANULAR PQR</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <anularComponent :session="session" :colores="colores" :rules="rules"></anularComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import anularComponent from '@/components/anular/anularComponent.vue'
export default {
  name: 'dialogoAnularComponent',
  components: {
    anularComponent
  },
  props: ['dialog', 'session', 'colores', 'rules'],
  data: () => ({

  }),
  methods: {
    cerrarVenta () {
      this.$emit('closed_anular')
    }
  }
}
</script>
