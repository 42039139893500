<template>
<div style="width:100%">
  <v-row>
    <v-col cols="12"><v-btn @click="reporte1" :color="colores.primario" dark>EXCEL DE PQRS</v-btn></v-col>
  </v-row>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'

export default {
  name: 'reportesComponent',
  components: {

  },
  props: ['anio', 'mes', 'session', 'colores'],
  mixins: [recFunciones, recEstadisticas],
  data: () => ({

  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    reporte1 () {
      this.rec_estadisticas_reporte1(this.anio, this.mes.numero)
    }

  } // FIN DE METODOS
}
</script>
