<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense>
      <v-toolbar-title>DATOS DEL REQUERIMIENTO</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" :md="(objs.retroalimentacion !== null && (objs.retroalimentacion.id === 30 || objs.retroalimentacion.id === 31)) ? 4 :6" class="pr-1">
          <v-autocomplete v-model="objs.retroalimentacion" return-object v-on:input="input_retroalimentacion(objs.retroalimentacion)" :rules="[rules.requerido]" :items="items.items_requerimientos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* RETROALIMENTACION' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" class="pr-1" v-if="objs.retroalimentacion !== null && (objs.retroalimentacion.id === 30 || objs.retroalimentacion.id === 31)">
          <v-autocomplete v-model="objs.dias" return-object v-on:input="input_dias(objs.dias)" :rules="[rules.requerido]" :items="items.items_dias" item-text="descripcion" item-value="id" :color="colores.secundario" label='* DIAS DE GESTION' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.sucursal_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_sucursales" item-text="descripcion" item-value="id" :color="colores.secundario" label='* SUCURSAL' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
        <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.ramo_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_ramos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* RAMO/PRODUCTO' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.proceso_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_procesos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* PROCESO' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.ptorecepcion_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_puntos_recepcion" item-text="descripcion" item-value="id" :color="colores.secundario" label='* PUNTO DE RECEPCIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.tipologia_id" :rules="[rules.requerido]" :items="items_tipologias" item-text="descripcion" item-value="id" :color="colores.secundario" :loading="loadingTipologia" label='* TIPOLOGIA' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card v-if="showSfc">
    <v-toolbar :color="colores.primario" dark dense>
      <v-toolbar-title>DETALLES QUEJA O RECLAMO</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.instancia_id"  :loading="loadingForm" :items="items.items_instancia_recepcion" item-text="descripcion" item-value="id" :color="colores.secundario" label='INSTANCIA DE RECEPCIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.canal_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_canales" item-text="descripcion" item-value="id" :color="colores.secundario" label='* CANAL' autocomplete="off"></v-autocomplete>
        </v-col>
       </v-row>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.desistimiento_id" clearable :loading="loadingForm" :items="items.items_desistimientos" item-text="descripcion" item-value="id" :color="colores.secundario" label='DESISTIMIENTO' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.quejaexpress_id" :loading="loadingForm" :items="items.items_queja_express" item-text="descripcion" item-value="id" :color="colores.secundario" label='QUEJA EXPRÉS' autocomplete="off"></v-autocomplete>
        </v-col>

      </v-row>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.admision_id"  :loading="loadingForm" :items="items.items_admision" item-text="descripcion" item-value="id" :color="colores.secundario" label='ADMISIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.productodigital_id"  :loading="loadingForm" :items="items.items_producto_digital" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRODUCTO DIGITAL' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.aceptacion_id"  :loading="loadingForm" :items="items.items_aceptacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='ACEPTACIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.rectificacion_id"  :loading="loadingForm" :items="items.items_rectificacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='RECTIFICACÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete  v-model="gestion.prorroga_id"  :loading="loadingForm" :items="items.items_prorroga" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRÓRROGA' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.marcacion_id" clearable :loading="loadingForm" :items="items.items_marcacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='MARCACIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="showSfc">
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.replica_id" v-on:input="input_replica(gestion.replica_id)" :loading="loadingForm" :items="items.items_replica" item-text="descripcion" item-value="id" :color="colores.secundario" label='RÉPLICA' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1" v-if="gestion.replica_id === 250">
          <v-text-field v-model="gestion.argumento_replica" label='ARGUMENTO RÉPLICA' autocomplete="off" :color="colores.secundario" maxlength="256" counter="256"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import recListados from '@/js/rec_listados.js'
export default {
  name: 'form_pqrComponent',
  components: {
  },
  mixins: [recListados],
  props: ['colores', 'session', 'objs', 'rules', 'items', 'loadingForm', 'gestion', 'items_archivos', 'otros'],
  data: () => ({
    loadingTipologia: false,
    showSfc: false,
    items_tipologias: []
  }),
  created () {
  },
  methods: {
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    input_dias (obj) {
      this.otros.dias = obj.descripcion
    },
    input_retroalimentacion (obj) {
      this.gestion.canal_id = null
      this.gestion.retroalimentacion_id = 0
      this.gestion.codigo_sfc = ''
      this.gestion.tutela_id = null
      this.gestion.entecontrol_id = null
      this.gestion.replica_id = null
      this.gestion.argumento_replica = null
      this.gestion.desistimiento_id = null
      this.gestion.quejaexpress_id = null
      this.gestion.instancia_id = null
      this.gestion.ptorecepcion_id = null
      this.gestion.admision_id = null
      this.gestion.productodigital_id = null
      this.gestion.aceptacion_id = null
      this.gestion.rectificacion_id = null
      this.gestion.prorroga_id = null
      this.gestion.marcacion_id = null
      this.objs.dias = null
      this.otros.dias = null
      this.showSfc = false
      if (obj.id === 31 || obj.id === 29) {
        this.showSfc = true
        this.gestion.tutela_id = 251
        this.gestion.admision_id = 298
        this.gestion.instancia_id = 283
        this.gestion.replica_id = 251
        this.gestion.quejaexpress_id = 251
        this.gestion.productodigital_id = 251
        this.gestion.prorroga_id = 251
        this.gestion.entecontrol_id = 277
        this.gestion.desistimiento_id = 280
        // this.gestion.ptorecepcion_id = 289
      }
      this.gestion.retroalimentacion_id = obj.id
      this.gestion.dias = (obj.accion === null) ? 0 : obj.accion.dias
      this.rec_listados_tipologias(obj.id)
    },
    input_replica () {
      this.gestion.argumento_replica = ''
    }
  }
}
</script>
