import axios from 'axios'
export default {
  methods: {
    rec_log_inicializar_log () {
      this.items_archivos = []
      this.logs.keyform = this.rec_funciones_key()
      this.logs.id_usuario = 0
      this.logs.gestion_id = 0
      this.logs.subestado_id = 0
      this.logs.descripcion = ''
      this.objs.objArea = null
      this.objs.logs_escalamientos = []
    },
    rec_log_inicializar_cliente () {
      this.logs_cliente.geslog_id = 0
      this.logs_cliente.correo = ''
    },
    rec_log_inicializar_cierre () {
      this.logs_cierre.gestion_id = 0
      this.logs_cierre.afavor_id = null
      this.logs_cierre.marcacion_id = null
      this.logs_cierre.roportunidad = ''
      this.logs_cierre.rcausal = ''
      this.logs_cierre.posiblescausas = ''
      this.logs_cierre.accionestomar = ''
      this.logs_cierre.sinopsis = ''
    },
    rec_log_nuevo () {
      if (this.logs.subestado_id === 5) { // SEGUIMIENTO
        this.rec_log_seguimiento()
      } else if (this.logs.subestado_id === 3) { // EN CLIENTE
        this.rec_log_cliente()
      } else if (this.logs.subestado_id === 4) { // CERRADO
        this.rec_log_cerrado()
      } else if (this.logs.subestado_id === 2) {
        this.rec_log_remitido()
      }
    },
    rec_log_datos () {
      this.logs.descripcion = this.rec_funciones_mayuscula(this.logs.descripcion)
      this.logs.id_usuario = this.session.id
      this.logs.gestion_id = this.registro_id

      this.logs_cierre.gestion_id = this.registro_id
      this.logs_cierre.roportunidad = this.rec_funciones_mayuscula(this.logs_cierre.roportunidad)
      this.logs_cierre.rcausal = this.rec_funciones_mayuscula(this.logs_cierre.rcausal)
      this.logs_cierre.posiblescausas = this.rec_funciones_mayuscula(this.logs_cierre.posiblescausas)
      this.logs_cierre.accionestomar = this.rec_funciones_mayuscula(this.logs_cierre.accionestomar)
      this.logs_cierre.sinopsis = this.rec_funciones_mayuscula(this.logs_cierre.sinopsis)
    },
    rec_log_cerrado () {
      this.rec_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion_cerrado'
      const data = { logs: this.logs, cierre: this.logs_cierre, registro: this.registro, id_usuario: this.session.id }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.dialogo.finalizo = true
        this.$refs.form.resetValidation()
        this.registro = response.data
        this.rec_log_inicializar_log()
        this.rec_log_inicializar_cliente()
        this.rec_log_inicializar_cierre()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_seguimiento () {
      this.rec_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion_seguimiento'
      const data = { logs: this.logs, prorroga_id: this.prorroga_id, accion: this.registro.retroalimentacion.accion, gesotro: this.registro.gesotro, gesadicional: this.registro.gesadicional, registro: this.registro }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.dialogo.finalizo = true
        this.$refs.form.resetValidation()
        this.registro = response.data
        this.rec_log_inicializar_log()
        this.rec_log_inicializar_cliente()
        this.rec_log_inicializar_cierre()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_cliente () {
      this.rec_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion_cliente'
      const data = { logs: this.logs, cliente: this.logs_cliente, prorroga_id: this.prorroga_id, accion: this.registro.retroalimentacion.accion, gesotro: this.registro.gesotro, gesadicional: this.registro.gesadicional, registro: this.registro }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.dialogo.finalizo = true
        this.$refs.form.resetValidation()
        this.registro = response.data
        this.rec_log_inicializar_log()
        this.rec_log_inicializar_cliente()
        this.rec_log_inicializar_cierre()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_log_remitido () {
      this.rec_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion_remitido'
      const data = { logs: this.logs, escalamientos: this.objs.logs_escalamientos, prorroga_id: this.prorroga_id, accion: this.registro.retroalimentacion.accion, gesotro: this.registro.gesotro, gesadicional: this.registro.gesadicional, registro: this.registro }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.dialogo.finalizo = true
        this.$refs.form.resetValidation()
        this.registro = response.data
        this.rec_log_inicializar_log()
        this.rec_log_inicializar_cliente()
        this.rec_log_inicializar_cierre()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    }

  }
}
