<template>
    <div style="width:100%">
        <v-card>
            <v-toolbar :color="colores.primario" dark dense>
                <v-toolbar-title>REPLICAS</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <template>
                    <div v-for="(item, index) in registro.replicas" :key="index">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-icon>mdi-calendar</v-icon>{{ rec_function_fecha_colombia(item.created_at) }}
                            </v-col>
                            <v-col cols="12" md="4" class="text-center">
                            </v-col>
                            <v-col cols="12" md="4" class="text-right">
                            </v-col>
                        </v-row>
                        <v-container fluid>
                            <span class="text-justify">{{ item.descripcion }}</span>
                        </v-container>
                        <v-divider></v-divider>
                        <br>
                    </div>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'lectura_replicaComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {
  },
  methods: {
  }
}
</script>
