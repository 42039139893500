<template>
<div style="width:100%">
  <v-dialog v-model="dialogInbound" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CREAR PQR</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-card-text>
            <horaComponent :tokenTiempo="tokenInbound" @eventotiempo="tiempo_pqr"></horaComponent>
          </v-card-text>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <forms :session="session" :tokenInbound="tokenInbound" :tiempo="tiempo" :rules="rules" :items="items" :loadingForm="loadingForm" :colores="colores"></forms>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import forms from '@/components/inbound/formsComponent.vue'
import horaComponent from '@/components/widgets/horaComponent.vue'
export default {
  name: 'bandejaDialogInboundComponent',
  components: {
    forms,
    horaComponent
  },
  props: ['dialogInbound', 'session', 'tokenInbound', 'rules', 'colores', 'items', 'loadingForm'],
  data: () => ({
    tiempo: 0
  }),
  methods: {
    cerrarVenta () {
      this.$emit('closed_inbound')
    },
    tiempo_pqr (ptiempo) {
      this.tiempo = ptiempo
    }
  }
}
</script>
