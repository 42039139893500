<template>
<div style="width:100%">
  <v-card>
    <v-card-text>
      <v-form ref="formbusqueda">
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="tipo_id" :loading="loadingForm" :rules="[rules.requerido]" :items="items.items_busquedas" item-text="descripcion" item-value="id" :color="colores.secundario" label="TIPO DE BUSQUEDA"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="dato" :rules="[rules.requerido]" :color="colores.secundario" maxlength="40" counter="40" label='* INFORMACION A BUSCAR' autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="12" md="1" align-self="center">
            <v-btn :loading="loadingBusqueda" @click="validar" block><v-icon>mdi-magnify</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <div  v-for="(item, index) in items_registros" :key="index">
        <sfcItem v-if="item.subestado_id === 6" :item="item" @view_sfc="ver_sfc"></sfcItem>
        <listado v-else :session="session" :rules="rules" :items="items" :colores="colores" :item="item"></listado>
      </div>
    </v-card-text>
  </v-card>
  <sfcDialog :dialogSfc="dialogSfc" :session="session" :items="items" :rules="rules" :colores="colores" :token="tokenSfc" :registro_id="registro_id" @closed_ventana_sfc="cerrar_ventana_sfc"></sfcDialog>
</div>
</template>

<script>

import recGestion from '@/js/rec_gestion.js'
export default {
  name: 'buscarComponent',
  components: {
    listado: () => import('@/components/inbound/buscar_pqrsComponent.vue'),
    sfcItem: () => import('@/components/bandejas/bandeja_pqrs_sfcComponent.vue'),
    sfcDialog: () => import('@/components/sfc/form_dialogoComponent.vue')
  },
  mixins: [recGestion],
  props: ['colores', 'rules', 'items', 'loadingForm', 'session'],
  data: () => ({
    tipo_id: 0,
    dato: '',
    loadingBusqueda: false,
    items_registros: [],
    dialogSfc: false,
    tokenSfc: 0,
    registro_id: 0
  }),
  methods: {
    validar () {
      if (this.$refs.formbusqueda.validate()) {
        this.rec_gestion_buscar(this.tipo_id, this.dato.trim())
      }
    },
    ver_sfc (pItem) {
      this.dialogSfc = true
      this.tokenSfc = this.tokenSfc + 1
      this.registro_id = pItem.id
    },
    cerrar_ventana_sfc () {
      this.dialogSfc = false
    }
  }

}
</script>
