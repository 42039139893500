<template>
<div style="width:100%">
  <v-row v-if="index==0 || index==1">
    <v-col cols="12" md="1"></v-col>
    <v-col cols="12" md="9">
      <v-row no-gutters>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario" dark elevation="1" class="text-center"><span style="font-size:8px">ABIERTO</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario" dark elevation="1" class="text-center"><span style="font-size:8px">REMITIDO</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario" dark elevation="1" class="text-center"><span style="font-size:8px">EN CLIENTE</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario" dark elevation="1" class="text-center"><span style="font-size:8px">SEGUIMIENTO</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario" dark elevation="1" class="text-center"><span style="font-size:8px">SFC</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet :color="colores.primario"  dark elevation="1" class="text-center"><span style="font-size:8px">CERRADO</span></v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="1" align="center" align-self="center">
      <v-avatar :color="colores.primario" size="36" class="white--text" style="font-size:14px">{{ mes.texto }}</v-avatar>
    </v-col>
    <v-col cols="12" md="9">
      <div v-for="(tipo, index) in objEstadistica.tipos" :key="index">
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <span style="font-size:10px"> {{ tipo.descripcion }}</span>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet color="grey" v-if="total_estados(tipo.id, mes.numero, 1)>0" elevation="1" dark class="text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 1) }} </span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet color="purple" v-if="total_estados(tipo.id, mes.numero, 2)>0" elevation="1" dark class=" text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 2) }}</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet v-if="total_estados(tipo.id, mes.numero, 3)>0" color="pink" elevation="1" dark class="text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 3) }}</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet v-if="total_estados(tipo.id, mes.numero, 5)>0" color="amber" elevation="1" dark class="text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 5) }}</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet v-if="total_estados(tipo.id, mes.numero, 6)>0" color="blue" dark elevation="1" class="text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 6) }}</span></v-sheet>
        </v-col>
        <v-col cols="12" md="1">
          <v-sheet v-if="total_estados(tipo.id, mes.numero, 4)>0" color="green" dark elevation="1" class="text-center"><span style="font-size:8px"> {{ total_estados(tipo.id, mes.numero, 4) }}</span></v-sheet>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      </div>
    </v-col>
    <v-col cols="12" md="1" align="center" align-self="center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="buscar(mes)" :color="colores.secundario" dark small elevation="2" v-bind="attrs" v-on="on"><v-icon>mdi-chart-areaspline</v-icon></v-btn>
        </template>
        <span>ESTADISTICA DEL MES</span>
      </v-tooltip>
    </v-col>
    <v-col cols="12" md="1"></v-col>
  </v-row>
  <v-divider></v-divider>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'
// import mesComponent from '@/components/estadisticas/mesComponent.vue'
export default {
  name: 'anualComponent',
  components: {

  },
  props: ['anio', 'colores', 'mes', 'objEstadistica', 'index'],
  mixins: [recFunciones, recEstadisticas],
  data: () => ({

  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    buscar (mes) {
      this.$emit('chart_mes', this.anio, mes)
    },
    total_estados (ptipo, pmes, estadoId) {
      let total = 0
      const gestiones = this.objEstadistica.gestiones
      for (let i = 0; i < gestiones.length; i++) {
        if (ptipo === gestiones[i].retroalimentacion_id && gestiones[i].mes === pmes && gestiones[i].subestado_id === estadoId) {
          total = gestiones[i].cantidad
        }
      }
      return total
    }

  } // FIN DE METODOS
}
</script>
