import axios from 'axios'
export default {
  methods: {
    rec_contacto_por_identificacion (pIdentificacion) {
      this.isloadingIden = false
      this.isSuccess = false
      const urlroute = this.$store.getters.getUrl + 'contactos_identificacion/' + pIdentificacion
      const data = { contacto: this.contacto, gestion: this.gestion }
      axios({
        url: urlroute,
        method: 'GET',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        if (response.data !== '') {
          this.isSuccess = true
          this.rec_contacto_entidad(response.data)
        } else {
          this.rec_contacto_inicializar()
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingCiudad = false
      })
    },
    rec_contacto_entidad (data) {
      // console.log('aqui')
      // console.log(data)
      // console.log('fue')
      this.contacto.gestion_id = 0
      this.contacto.documento_id = data.documento_id
      this.contacto.nombres = data.nombres
      this.contacto.apellido1 = data.apellido1
      this.contacto.apellido2 = data.empresa
      this.contacto.empresa = data.empresa
      this.contacto.cargo = data.cargo
      this.contacto.telefono = data.telefono
      this.contacto.celular = data.celular
      this.contacto.pais_id = data.pais_id
      this.contacto.departamento_id = data.departamento_id
      this.rec_departamento_por_id(data.departamento_id)
      this.contacto.ciudad_id = data.ciudad_id
      this.contacto.correo = data.correo
      this.contacto.direccion = data.direccion
      this.contacto.tipopersona_id = data.tipopersona_id
      this.contacto.sexo_id = data.sexo_id
      this.contacto.lgbtiq_id = data.lgbtiq_id
      this.contacto.especial_id = data.especial_id
    },
    rec_contacto_inicializar () {
      this.contacto.gestion_id = 0
      this.contacto.documento_id = 0
      this.contacto.nombres = ''
      this.contacto.apellido1 = ''
      this.contacto.apellido2 = ''
      this.contacto.empresa = ''
      this.contacto.cargo = ''
      this.contacto.telefono = ''
      this.contacto.celular = ''
      this.contacto.pais_id = null
      this.contacto.departamento_id = null
      this.contacto.ciudad_id = null
      this.contacto.correo = ''
      this.contacto.direccion = ''
      this.contacto.tipopersona_id = null
      this.contacto.sexo_id = null
      this.contacto.lgbtiq_id = null
      this.contacto.especial_id = null
    }

  }
}
