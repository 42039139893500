<template>
  <div style="width:100%">
  <v-text-field :value="(obj===null)? '':obj.descripcion" :label="titulo" :color="colores.primario" dense filled readonly></v-text-field>
  <!--
    <v-row no-gutters>
      <v-col cols="12" :style="[{'color': colores.black},{'font-size': '13px'}]">
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" style="font-size: 16px">
        {{ descripcion }}
      </v-col>
    </v-row>
    -->
  </div>
</template>
<script>
export default {
  props: ['colores', 'titulo', 'obj'],
  data: () => ({
  }), // FIN DE DATA
  created () {}, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {} // FIN DE METODOS
}
</script>
