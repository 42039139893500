import { render, staticRenderFns } from "./ver_archivosComponent.vue?vue&type=template&id=0375da18&"
import script from "./ver_archivosComponent.vue?vue&type=script&lang=js&"
export * from "./ver_archivosComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VIcon,VListItem,VListItemAction,VListItemActionText,VListItemContent})
