<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="6">
      <texto2 :colores="colores" :titulo="'A FAVOR'" :descripcion="gescierre.afavor.descripcion"></texto2>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-row no-gutters>
    <v-col cols="12" md="6">
      <texto2 :colores="colores" :titulo="'RESPONSABLE OPORTUNIDAD'" :descripcion="gescierre.roportunidad"></texto2>
    </v-col>
    <v-col cols="12" md="6">
      <texto2 :colores="colores" :titulo="'RESPONSABLE CAUSA'" :descripcion="gescierre.rcausal"></texto2>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-row no-gutters>
    <v-col cols="12" md="6">
      <texto2 :colores="colores" :titulo="'POSIBLES CAUSAS'" :descripcion="gescierre.posiblescausas"></texto2>
    </v-col>
    <v-col cols="12" md="6">
      <texto2 :colores="colores" :titulo="'ACCIONES A TOMAR'" :descripcion="gescierre.acciontomar"></texto2>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  <v-row no-gutters>
    <v-col cols="12">
      <texto2 :colores="colores" :titulo="'SINOPSIS'" :descripcion="gescierre.sinopsis"></texto2>
    </v-col>
  </v-row>
</div>
</template>

<script>
import texto2 from '@/components/widgets/text2Component.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'lectura_cierreComponent',
  components: {
    texto2
  },
  mixins: [recFunciones],
  props: ['colores', 'gescierre'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }
}
</script>
