import axios from 'axios'
export default {
  methods: {
    rec_form_bandeja () {
      this.loadingForm = true
      this.items.items_busquedas = []
      this.items.items_procesos = []
      this.items.items_requerimientos = []
      this.items.items_canales = []
      this.items.items_sucursales = []
      this.items.items_usuarios = []
      this.items.items_favorabilidad = []
      this.items.items_documentos = []
      this.items.items_ramos = []
      this.items.items_subestados = []
      this.items.items_departamentos = []
      this.items.items_subestadoslogs = []
      this.items.items_aceptacion = []
      this.items.items_admision = []
      this.items.items_condicion_especial = []
      this.items.items_desistimientos = []
      this.items.items_ente_control = []
      this.items.items_instancia_recepcion = []
      this.items.items_lgbtiq = []
      this.items.items_paises = []
      this.items.items_marcacion = []
      this.items.items_puntos_recepcion = []
      this.items.items_rectificacion = []
      this.items.items_sexo = []
      this.items.items_tutela = []
      this.items.items_ororroga = []
      this.items.items_replica = []
      this.items.items_producto_digital = []
      this.items.items_queja_express = []
      this.items.items_tipo_personas = []
      this.items.items_tipo_usuarios = []
      this.items.items_dias = []
      const urlroute = this.$store.getters.getUrl + 'form_bandeja'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        // console.log('listados', response)
        this.items.items_busquedas = response.data.items_busquedas
        this.items.items_procesos = response.data.items_procesos
        this.items.items_requerimientos = response.data.items_requerimientos
        this.items.items_canales = response.data.items_canales
        this.items.items_sucursales = response.data.items_sucursales
        this.items.items_usuarios = response.data.items_usuarios
        this.items.items_favorabilidad = response.data.items_favorabilidad
        this.items.items_documentos = response.data.items_documentos
        this.items.items_ramos = response.data.items_ramos
        this.items.items_subestados = response.data.items_subestados
        this.items.items_tipologias = response.data.items_tipologias
        this.items.items_departamentos = response.data.items_departamentos
        this.items.items_subestadoslogs = response.data.items_subestadoslogs
        this.items.items_areas = response.data.items_areas

        this.items.items_aceptacion = response.data.items_aceptacion
        this.items.items_admision = response.data.items_admision
        this.items.items_condicion_especial = response.data.items_condicion_especial
        this.items.items_desistimientos = response.data.items_desistimientos
        this.items.items_ente_control = response.data.items_ente_control
        this.items.items_instancia_recepcion = response.data.items_instancia_recepcion
        this.items.items_lgbtiq = response.data.items_lgbtiq
        this.items.items_tutela = response.data.items_lgbtiq
        this.items.items_replica = response.data.items_lgbtiq
        this.items.items_prorroga = response.data.items_lgbtiq
        this.items.items_producto_digital = response.data.items_lgbtiq
        this.items.items_queja_express = response.data.items_lgbtiq
        this.items.items_paises = response.data.items_paises
        this.items.items_marcacion = response.data.items_marcacion
        this.items.items_puntos_recepcion = response.data.items_puntos_recepcion
        this.items.items_rectificacion = response.data.items_rectificacion
        this.items.items_sexo = response.data.items_sexo
        this.items.items_tipo_personas = response.data.items_tipo_personas
        this.items.items_tipo_usuarios = response.data.items_usuarios
        this.items.items_dias = response.data.items_dias
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingForm = false
      })
    }

  }
}
