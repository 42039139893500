<template>
<div style="width:100%">
  <v-list>
    <div v-for="(item, index) in session.permisos" :key="index">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar  color="indigo" size="36"><span class="white--text text-h6"> {{ (index + 1) }} </span></v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title>{{ item.aplicativo.titulo }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
              <v-btn @click="abrirVentana(item)" icon>
                <v-icon color="grey lighten-1">{{ item.aplicativo.icono }}</v-icon>
              </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
    </div>
  </v-list>
  <dialogoEstadisticas :dialog="dialogEstadisticas" :session="session" :colores="colores" :rules="rules" @closed_estadisticas="cerrar_estadisticas"></dialogoEstadisticas>
  <dialogoAnular  :dialog="dialogAnular" :session="session" :colores="colores" :rules="rules" @closed_anular="cerrar_anular"></dialogoAnular>
</div>
</template>

<script>
import dialogoEstadisticas from '@/components/estadisticas/dialogoComponent.vue'
import dialogoAnular from '@/components/anular/dialogoComponent.vue'
export default {
  name: 'aplicativosComponent',
  components: {
    dialogoEstadisticas,
    dialogoAnular
  },
  props: ['session', 'colores', 'rules'],
  data: () => ({
    dialogEstadisticas: false,
    dialogAnular: false
  }),
  methods: {
    abrirVentana (item) {
      if (item.aplicativo_id === 1) {
        window.open('http://webtopfactory.outsourcingcos.com/websigplus/usuariovalidarcrm.php?pid=200&pusu=' + this.session.id, '_blank')
      } else if (item.aplicativo_id === 2) {
        this.dialogEstadisticas = true
      } else if (item.aplicativo_id === 3) {
        this.dialogAnular = true
      }
    },
    cerrar_estadisticas () {
      this.dialogEstadisticas = false
    },
    cerrar_anular () {
      this.dialogAnular = false
    }
  }
}
</script>
