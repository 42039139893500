<template>
  <div style="width:100%">
    <v-dialog v-model="dialog" width="100%" persistent>
      <v-card>
        <v-toolbar dense :color="colores.primario" dark>
          <span style="font-size:20px"><b>EDICION/DATOS DEL REQUERIMIENTO</b></span>
          <v-spacer></v-spacer>
          <v-btn @click="cerrarVenta" icon color="white">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete v-model="gestion.ptorecepcion_id" :rules="[rules.requerido]" :items="items.items_puntos_recepcion" item-text="descripcion" item-value="id" :color="colores.secundario" label='* PUNTO DE RECEPCIÓN' autocomplete="off"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="gestion.sucursal_id" :rules="[rules.requerido]" :items="items.items_sucursales" item-text="descripcion" item-value="id" :color="colores.secundario" label='* SUCURSAL' autocomplete="off"></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete v-model="gestion.ramo_id" :rules="[rules.requerido]"  :items="items.items_ramos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* RAMO/PRODUCTO' autocomplete="off"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="gestion.proceso_id" :rules="[rules.requerido]" :items="items.items_procesos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* PROCESO' autocomplete="off"></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-autocomplete v-if="registro.retroalimentacion_id !== 29 && registro.retroalimentacion_id !== 31" v-model="objs.retroalimentacion" v-on:input="input_retroalimentacion(objs.retroalimentacion)" return-object :rules="[rules.requerido]" :items="items_requerimientos" item-text="descripcion" item-value="id" :color="colores.secundario"  label='* RETROALIMENTACION' autocomplete="off"></v-autocomplete>
                <texto2  v-if="registro.retroalimentacion_id === 29 || registro.retroalimentacion_id === 31" :colores="colores" :titulo="'RETROALIMENTACION'" :descripcion="registro.retroalimentacion.descripcion"></texto2>
              </v-col>
              <v-col cols="12" md="6">
                <!-- <texto2 v-if="registro.tipologia !== null" :colores="colores" :titulo="'TIPOLOGIA'" :descripcion="registro.tipologia.descripcion"></texto2> -->
                <v-autocomplete v-if="registro.retroalimentacion_id !== 29 && registro.retroalimentacion_id !== 31" v-model="gestion.tipologia_id" :rules="[rules.requerido]" :items="items_tipologias" item-text="descripcion" item-value="id" :color="colores.secundario"  label='* TIPOLOGIA' autocomplete="off"></v-autocomplete>
                <texto2 v-if="registro.retroalimentacion_id === 29 || registro.retroalimentacion_id === 31"  :colores="colores" :titulo="'TIPOLOGIA'" :descripcion="registro.tipologia.descripcion"></texto2>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="gestion.tipologia_id">
              <v-col md="12" cols="12">
                <v-btn @click="actualizarDatosRequerimiento" :loading="loadingForm" block elevation="10">Guardar</v-btn>
              </v-col>
            </v-row>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import texto2 from '@/components/widgets/text2Component.vue'
import recFunciones from '@/js/rec_funciones.js'
import recListados from '@/js/rec_listados.js'
import recGestion from '@/js/rec_gestion.js'

export default {
  name: 'edicion_headerComponent.vue',
  components: {
    texto2
  },
  mixins: [recFunciones, recGestion, recListados],
  props: ['colores', 'registro', 'dialog', 'items', 'rules'],
  data: () => ({
    objs: { retroalimentacion: null },
    gestion: { aplicativo_id: 319, keyform: 0, id_usuario: 0, canal_id: 0, retroalimentacion_id: 0, tipologia_id: 0, sucursal_id: 0, proceso_id: 0, ramo_id: 0, descripcion: '', subestado_id: 1, segundos: 0, id_usuariocerro: 0, fechacerrado: null, fechaparacerrar: null, dias: 0, codigo_sfc: '', tutela_id: null, entecontrol_id: null, replica_id: null, argumento_replica: null, desistimiento_id: null, quejaexpress_id: null, instancia_id: null, ptorecepcion_id: null, admision_id: null, productodigital_id: null, aceptacion_id: null, rectificacion_id: null, prorroga_id: null, marcacion_id: null },
    loadingForm: false,
    items_tipologias: [],
    items_requerimientos: []
  }),
  created () {
    this.items_inicio()
    this.input_retroalimentacion(this.registro.retroalimentacion)
    this.llenarDatos()
  },
  methods: {
    cerrarVenta () {
      this.$emit('closed_header')
    },
    items_inicio () {
      this.items_requerimientos = this.items.items_requerimientos.filter((e) => e.id !== 31 && e.id !== 29)
    },
    input_retroalimentacion (obj) {
      this.gestion.retroalimentacion_id = obj.id
      this.gestion.tipologia_id = 0
      this.rec_listados_tipologias(obj.id)
    },
    llenarDatos () {
      this.objs.retroalimentacion = this.registro.retroalimentacion
      this.gestion.retroalimentacion_id = this.registro.retroalimentacion_id
      this.gestion.ptorecepcion_id = this.registro.ptorecepcion_id
      this.gestion.sucursal_id = this.registro.sucursal_id
      this.gestion.ramo_id = this.registro.ramo_id
      this.gestion.proceso_id = this.registro.proceso_id
      this.gestion.tipologia_id = this.registro.tipologia_id
    },
    actualizarDatosRequerimiento () {
      this.rec_update_datos_del_requerimiento(this.gestion.ptorecepcion_id, this.gestion.sucursal_id, this.gestion.ramo_id, this.gestion.proceso_id, this.registro.id, this.gestion.tipologia_id, this.gestion.retroalimentacion_id, this.registro)
    },
    actualizarQR () {
      this.$emit('actualizar_QR')
    }
  }
}
</script>
