import axios from 'axios'
export default {
  methods: {
    rec_sfc_id (pId) {
      this.loadingRegistro = true
      this.registro = null
      const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.registro = response.data
        this.rec_sfc_entidad(response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingRegistro = false
      })
    },
    rec_sfc_entidad (data) {
      // this.gestion.canal_id = data.canal_id
      this.gestion.retroalimentacion_id = 0
      this.gestion.tipologia_id = data.tipologia_id
      this.gestion.sucursal_id = 0
      this.gestion.proceso_id = 0
      this.gestion.ramo_id = data.ramo_id
      this.gestion.descripcion = data.descripcion
      this.gestion.subestado_id = 1
      this.gestion.segundos = 0
      this.gestion.id_usuariocerro = 0
      this.gestion.fechacerrado = null
      this.gestion.fechaparacerrar = null
      this.gestion.dias = 0
      this.gestion.codigo_sfc = ''
      this.gestion.tutela_id = data.tutela_id
      this.gestion.entecontrol_id = data.entecontrol_id
      this.gestion.replica_id = data.replica_id
      this.gestion.argumento_replica = ''
      this.gestion.desistimiento_id = null
      this.gestion.quejaexpress_id = data.quejaexpress_id
      this.gestion.instancia_id = null
      this.gestion.ptorecepcion_id = null
      this.gestion.admision_id = null
      this.gestion.productodigital_id = null
      this.gestion.aceptacion_id = null
      this.gestion.rectificacion_id = null
      this.gestion.prorroga_id = null
      this.gestion.marcacion_id = null

      // CONTACTO
      this.contacto.documento_id = data.gescontacto.documento_id
      this.contacto.identificacion = data.gescontacto.identificacion
      this.contacto.nombres = data.gescontacto.nombres
      this.contacto.apellido1 = ''
      this.contacto.apellido2 = ''
      this.contacto.empresa = ''
      this.contacto.cargo = ''
      this.contacto.telefono = (data.gescontacto.celular.length < 10) ? data.gescontacto.celular : ''
      this.contacto.celular = (data.gescontacto.celular.length >= 10) ? data.gescontacto.celular : ''
      this.contacto.departamento_id = data.gescontacto.departamento_id
      this.contacto.ciudad_id = data.gescontacto.ciudad_id
      this.contacto.correo = (data.gescontacto.correo === null) ? '' : data.gescontacto.correo
      this.contacto.pais_id = 156
      this.contacto.direccion = (data.gescontacto.direccion === null) ? '' : data.gescontacto.direccion
      this.contacto.tipopersona_id = data.gescontacto.tipopersona_id
      this.contacto.sexo_id = data.gescontacto.sexo_id
      this.contacto.lgbtiq_id = data.gescontacto.lgbtiq_id
      this.contacto.especial_id = data.gescontacto.especial_id
    },
    rec_sfc_datos () {
      this.gestion.descripcion = this.rec_funciones_mayuscula(this.gestion.descripcion)
      this.gestion.id_usuario = this.session.id
      this.gestion.subestado_id = 1
      this.gestion.segundos = this.tiempo

      this.contacto.nombres = this.rec_funciones_mayuscula(this.contacto.nombres)
      this.contacto.apellido1 = this.rec_funciones_mayuscula(this.contacto.apellido1)
      this.contacto.apellido2 = this.rec_funciones_mayuscula(this.contacto.apellido2)
      this.contacto.empresa = this.rec_funciones_mayuscula(this.contacto.empresa)
      this.contacto.cargo = this.rec_funciones_mayuscula(this.contacto.cargo)
    },
    rec_sfc_nuevo () {
      this.rec_sfc_datos()

      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      const urlroute = this.$store.getters.getUrl + 'gestion_sfc'
      const data = { id_usuario: this.session.id, registro: this.registro, contacto: this.contacto, gestion: this.gestion, escalamientos: [] }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {

          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.registro = response.data
        this.dialogo.detalle = true
        this.dialogo.objPayload = response.data
        this.rec_gestion_inicializar_contacto()
        this.rec_gestion_inicializar_pqr()
        this.gestion.keyform = this.rec_funciones_key()
        this.$refs.form.resetValidation()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_inicializar_contacto () {
      this.contacto.gestion_id = 0
      this.contacto.documento_id = 0
      this.contacto.identificacion = ''
      this.contacto.nombres = ''
      this.contacto.apellido1 = ''
      this.contacto.apellido2 = ''
      this.contacto.empresa = ''
      this.contacto.cargo = ''
      this.contacto.telefono = ''
      this.contacto.celular = ''
      this.contacto.departamento_id = null
      this.contacto.ciudad_id = null
      this.contacto.correo = ''
      this.contacto.pais_id = 156
      this.contacto.direccion = null
      this.contacto.tipopersona_id = null
      this.contacto.sexo_id = null
      this.contacto.lgbtiq_id = null
      this.contacto.especial_id = null
    },
    rec_gestion_inicializar_pqr () {
      this.items_archivos = []
      this.gestion.keyform = this.rec_funciones_key()
      this.objs.retroalimentacion = null
      this.gestion.aplicativo_id = 319
      this.gestion.canal_id = null
      this.gestion.retroalimentacion_id = 0
      this.gestion.tipologia_id = 0
      this.gestion.sucursal_id = 0
      this.gestion.proceso_id = 0
      this.gestion.ramo_id = 0
      this.gestion.descripcion = ''
      this.gestion.subestado_id = 0
      this.gestion.segundos = 0
      this.gestion.id_usuariocerro = 0
      this.gestion.fechacerrado = null
      this.gestion.fechaparacerrar = null
      this.gestion.dias = 0
      this.gestion.codigo_sfc = ''
      this.gestion.tutela_id = null
      this.gestion.entecontrol_id = null
      this.gestion.replica_id = null
      this.gestion.argumento_replica = null
      this.gestion.desistimiento_id = null
      this.gestion.quejaexpress_id = null
      this.gestion.instancia_id = null
      this.gestion.ptorecepcion_id = null
      this.gestion.admision_id = null
      this.gestion.productodigital_id = null
      this.gestion.aceptacion_id = null
      this.gestion.rectificacion_id = null
      this.gestion.prorroga_id = null
      this.gestion.marcacion_id = null
    }

  }
}
