<template>
  <div style="width:100%">
    <menuTop :colores="colores" :session="session"></menuTop>
    <!-- <menusLeft :colores="colores"></menusLeft> -->
    <!-- <menusRight :colores="colores"></menusRight> -->
    <bandeja :objGlobal="objGlobal" :session="session" :rules="rules" :items="items" :loadingForm="loadingForm" :colores="colores" @update_pqrs="actualizar_pqrs"></bandeja>
  </div>
</template>

<script>
import menuTop from '@/components/menus/menu_topComponent.vue'
// import menusLeft from '@/components/menus/menu_leftComponent.vue'
// import menusRight from '@/components/menus/menu_rightComponent.vue'
import bandeja from '@/components/bandejas/bandejaComponent.vue'
import recForm from '@/js/rec_forms.js'
import recBandeja from '@/js/rec_bandeja.js'
export default {
  name: 'Bandeja',
  components: {
    menuTop,
    // menusLeft,
    // menusRight,
    bandeja
  },
  mixins: [recForm, recBandeja],
  data: () => ({
    colores: null,
    session: { id: 0, nombres: '', apellidos: '', correo: '', identificacion: '', tipo: '', area: [], permisos: [] },
    loadingForm: false,
    items: { items_busquedas: [], items_procesos: [], items_requerimientos: [], items_canales: [], items_sucursales: [], items_usuarios: [], items_favorabilidad: [], items_documentos: [], items_ramos: [], items_subestados: [], items_subestadoslogs: [], items_tipologias: [], items_departamentos: [], items_areas: [], items_dias: [] },
    rules: null,
    objGlobal: { isFiltro: false, items_escalamientos: [] }
  }),
  created () {
    this.colores = this.$store.getters.getColores
    // SERVER LOCAL
    // const usu = this.$route.query.idusu
    // if (usu !== undefined) {
    //   this.session.id = this.$route.query.idusu
    // } else {
    //   this.session = this.$store.getters.getSession
    // }

    // SERVER PUBLICOs
    const s = this.$store.getters.getSession
    if (s.id > 0) {
      this.session = this.$store.getters.getSession
    }
    const rules = { requerido: v => !!v || 'Requerido', email: v => (v.length === 0) || ((v && /.+@.+\..+/.test(v)) || 'E-mail invalido'), long5: v => (v && v.length > 5) || 'Debe ser mayor a 5 digitos', long10: v => (v && v.length >= 10) || 'Debe ser mayor a 10 digitos', igual7: v => (v.length === 0 || v.length === 7) || 'Debe ser igual a 7 digitos', igual10: v => (v.length === 0 || v.length === 10) || 'Debe ser igual a 10 digitos', combocero: v => (v && v !== 0) || 'Requerido', numerico: v => (v.length === 0 || (!isNaN(parseFloat(v)) && v >= 0)) || 'Numero invalido' }
    this.$store.commit('setRules', rules)
    this.rules = this.$store.getters.getRules
    this.rec_bandeja_escalamientos(this.session.id)
    this.rec_form_bandeja()
    this.startInterval()
  },
  methods: {
    actualizar_pqrs () {
      this.rec_bandeja_escalamientos(this.session.id)
    },
    startInterval () {
      setInterval(() => {
        // 10000 - 10 segundos
        // 60000 - 60 segundos
        // 120000 - 2 minutos
        // 240000 - 4 minutos
        if (this.objGlobal.isFiltro === false) {
          this.rec_bandeja_escalamientos(this.session.id)
        }
      }, 120000)
    }
  }

}
</script>
