<template>
<div style="width:100%">
  <div v-for="(item, index) in items_escalamientos" :key="index">
    <sfcItem v-if="item.subestado_id === 6" :item="item" @view_sfc="ver_sfc"></sfcItem>
    <crmItem v-else :item="item" @view_crm="ver_crm"></crmItem>
  </div>
  <lecturaDialog :dialogLectura="dialogLectura" :session="session" :items="items" :rules="rules" :colores="colores" :token="token" :registro_id="registro_id" @closed_ventana="cerrar_ventana"></lecturaDialog>
  <sfcDialog :dialogSfc="dialogSfc" :session="session" :items="items" :rules="rules" :colores="colores" :token="tokenSfc" :registro_id="registro_id" @closed_ventana_sfc="cerrar_ventana_sfc"></sfcDialog>
  </div>
</template>

<script>
import crmItem from '@/components/bandejas/bandeja_pqrs_crmComponent.vue'
import sfcItem from '@/components/bandejas/bandeja_pqrs_sfcComponent.vue'

import lecturaDialog from '@/components/inbound/lectura_dialogoComponent.vue'
import sfcDialog from '@/components/sfc/form_dialogoComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_pqr_listadoComponent',
  components: {
    crmItem,
    sfcItem,
    sfcDialog,
    lecturaDialog
  },
  props: ['session', 'items_escalamientos', 'colores', 'rules', 'items'],
  mixins: [recFunciones],
  data: () => ({
    dialogSfc: false,
    tokenSfc: 0,
    dialogLectura: false,
    token: 0,
    registro_id: 0
  }),
  methods: {
    colorVencimiento (item) {
      // console.log(item.dias)
      let color = 'black'
      if (item.dias < -5) {
        color = 'green'
      } else if (item.dias >= -5 && item.dias <= -1) {
        color = 'amber'
      } else {
        color = 'red'
      }
      return color
    },
    abrirColores () {
      alert('1')
    },
    ver_crm (pItem) {
      this.dialogLectura = true
      this.token = this.token + 1
      this.registro_id = pItem.id
    },
    ver_sfc (pItem) {
      this.dialogSfc = true
      this.tokenSfc = this.tokenSfc + 1
      this.registro_id = pItem.id
    },
    cerrar_ventana () {
      this.dialogLectura = false
    },
    cerrar_ventana_sfc () {
      this.dialogSfc = false
    }
  }
}
</script>
