<template>
<div style="width:100%">
  <v-container fluid v-if="loadingRegistro">
    <br>
    <center><v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate></v-progress-circular></center>
  </v-container>
  <v-container fluid v-else>
    <br>
    <div v-if="registro.subestado_id === 6">
      <lecturaHeader :colores="colores" :registro="registro"></lecturaHeader>
      <v-form ref="form">
        <asegurado :contacto="contacto" :gescontacto="registro.gescontacto" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingRegistro"></asegurado>
        <br>
        <pqr :registro="registro" :objs="objs" :session="session" :gestion="gestion" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingRegistro" :items_archivos="items_archivos"></pqr>
        <br>
      </v-form>
      <v-btn rounded @click="validar" block dark>GUARDAR</v-btn>
      <br><br><br><br>
    </div>
    <div v-else>
      <lectura :session="session" :items="items" :rules="rules" :colores="colores" :token="token" :registro_id="registro_id"></lectura>
      <br>
    </div>
  </v-container>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recSfc from '@/js/rec_sfc.js'
import recFunciones from '@/js/rec_funciones.js'

export default {
  name: 'lecturaComponent',
  components: {
    lectura: () => import('@/components/inbound/lecturaComponent.vue'),
    lecturaHeader: () => import('@/components/inbound/lectura_headerComponent.vue'),
    asegurado: () => import('@/components/sfc/form_aseguradoComponent.vue'),
    pqr: () => import('@/components/sfc/form_pqrComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recFunciones, recSfc],
  props: ['dialogSfc', 'session', 'colores', 'token', 'registro_id', 'rules', 'items'],
  data: () => ({
    registro: null,
    objs: { retroalimentacion: null },
    gestion: { aplicativo_id: 319, keyform: 0, id_usuario: 0, canal_id: 0, retroalimentacion_id: 0, tipologia_id: 0, sucursal_id: 0, proceso_id: 0, ramo_id: 0, descripcion: '', subestado_id: 1, segundos: 0, id_usuariocerro: 0, fechacerrado: null, fechaparacerrar: null, dias: 0, codigo_sfc: '', tutela_id: null, entecontrol_id: null, replica_id: null, argumento_replica: '', desistimiento_id: null, quejaexpress_id: null, instancia_id: null, ptorecepcion_id: null, admision_id: null, productodigital_id: null, aceptacion_id: null, rectificacion_id: null, prorroga_id: null, marcacion_id: null },
    contacto: { gestion_id: 0, documento_id: 0, identificacion: '', nombres: '', apellido1: '', apellido2: '', empresa: '', cargo: '', telefono: '', celular: '', departamento_id: null, ciudad_id: null, correo: '', pais_id: 156, direccion: '', tipopersona_id: null, sexo_id: null, lgbtiq_id: null, especial_id: null },
    loadingRegistro: false,
    items_archivos: [],
    escalamientos: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  watch: {
    token: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_sfc_id(this.registro_id)
      }
    }
  },
  created () {

  },
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_sfc_nuevo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    }

  }
}
</script>
