import axios from 'axios'
export default {
  methods: {
    rec_upload_gestion (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        // auth: {
        //  username: this.$store.getters.getHeadUsuario,
        //  password: this.$store.getters.getHeadPassword
        // }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_log (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'log_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        // auth: {
        //  username: this.$store.getters.getHeadUsuario,
        //  password: this.$store.getters.getHeadPassword
        // }
      }).then(response => {
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },

    rec_upload_cierre (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'cierre_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        // auth: {
        //  username: this.$store.getters.getHeadUsuario,
        //  password: this.$store.getters.getHeadPassword
        // }
      }).then(response => {
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'gestion_download/' + pId
      console.log(this.$store.getters.getUrlFile)
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        window.open(this.$store.getters.getUrlFile + response.data.archivo, '_blank')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_log_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'log_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        window.open(this.$store.getters.getUrlFile + response.data.archivo, '_blank')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_upload_cierre_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'cierre_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        window.open(this.$store.getters.getUrlFile + response.data.archivo, '_blank')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }
  }
}
