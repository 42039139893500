<template>
  <div style="width:100%">
    <v-dialog v-model="dialog" width="100%" persistent>
      <v-card>
        <v-toolbar dense :color="colores.primario" dark>
          <span style="font-size:20px"><b>EDICION/CONTACTO</b></span>
          <v-spacer></v-spacer>
          <v-btn @click="cerrarVenta" icon color="white">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-2">
          <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'CODIGO DE LA QUEJA'" :descripcion="registro.codigo"></texto2></v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'ESTADO'" :descripcion="registro.subestado.descripcion"></texto2>
          </v-col>
        </v-row>
           <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.identificacion" :rules="[rules.requerido]" v-on:blur="blur_identificacion(contacto.identificacion)" :success="isSuccess" maxlength="15" counter="15"  label="IDENTIFICACIÓN *" :color="colores.secundario" type="number" min="0" autocomplete="off">
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular v-if="isloadingIden" size="24" indeterminate></v-progress-circular>
              </v-fade-transition>
            </template>
          </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-on:change="changeTipoDocumento" v-model="contacto.documento_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_documentos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* TIPO DE DOCUMENTO' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
           <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.nombres" :rules="[rules.requerido]" label='* NOMBRES' autocomplete="off" :color="colores.secundario" maxlength="40" counter="40"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pr-1">
            <v-text-field v-model="contacto.apellido1" :rules="[rules.requerido]" label='* PRIMER APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="contacto.apellido2" label='SEGUNDO APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.empresa" label='EMPRESA' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="contacto.cargo" label='CARGO' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.telefono" label='TELEFONO'  @keypress="isNumber($event)"  autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="contacto.celular" label='CELULAR' @keypress="isNumber($event)"  autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.direccion" label='DIRECCIÓN' autocomplete="off" :color="colores.secundario" maxlength="250" counter="250"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="contacto.pais_id"  v-on:input="input_pais(contacto.pais_id)" :loading="loadingForm" :items="items.items_paises" item-text="descripcion" item-value="id" :color="colores.secundario" label='PAÍS' autocomplete="on"></v-autocomplete>
          </v-col>
        </v-row>
             <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="contacto.departamento_id" :rules="[rules.requerido]" v-on:input="input_departamento(contacto.departamento_id)" :loading="loadingForm" :items="items.items_departamentos" item-text="descripcion" item-value="id" :color="colores.secundario" label='DEPARTAMENTO' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="contacto.ciudad_id" :rules="[rules.requerido]" :loading="loadingCiudad" :items="items_ciudades" item-text="descripcion" item-value="id" :color="colores.secundario" label='CIUDAD' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field v-model="contacto.correo" :rules="[rules.requerido, rules.email]" label='* CORREO' autocomplete="off" :color="colores.secundario" maxlength="60" counter="60"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="contacto.direccion" label='DIRECCIÓN' autocomplete="off" :color="colores.secundario" maxlength="250" counter="250"></v-text-field>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete disabled v-model="contacto.tipopersona_id" :loading="loadingForm" :items="items.items_tipo_personas" item-text="descripcion" item-value="id" :color="colores.secundario" label='TIPO DE PERSONA' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-autocomplete v-model="contacto.tipousuario_id" :loading="loadingForm" :items="items.items_tipo_usuarios" item-text="descripcion" item-value="id" :color="colores.secundario" label='TIPO DE USUARIO' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete v-model="contacto.sexo_id" clearable :loading="loadingForm" :items="items.items_sexo" item-text="descripcion" item-value="id" :color="colores.secundario" label='SEXO' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="contacto.lgbtiq_id" clearable :loading="loadingForm" :items="items.items_lgbtiq" item-text="descripcion" item-value="id" :color="colores.secundario" label='LGTBIQ' autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="contacto.especial_id" clearable :loading="loadingForm" :items="items.items_condicion_especial" item-text="descripcion" item-value="id" :color="colores.secundario" label='CONDICIÓN ESPECIAL' autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
          <v-divider></v-divider>
          <v-row>
              <v-col md="12" cols="12">
                <v-btn @click="actualizarDatosAsegurado" :loading="this.loadingForm" block elevation="10">Guardar</v-btn>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import texto2 from '@/components/widgets/text2Component.vue'
import recDepartamento from '@/js/rec_departamentos.js'
import recContactos from '@/js/rec_contactos.js'
import recGestion from '@/js/rec_gestion.js'
export default {
  name: 'edicion_aseguradoComponent.vue',
  components: {
    texto2
  },
  mixins: [recDepartamento, recContactos, recGestion],
  data: () => ({
    dialogAsegurado: false,
    loadingForm: false,
    isSuccess: false,
    isloadingIden: false,
    loadingCiudad: false,
    items_ciudades: [],
    contacto: { gestion_id: 0, documento_id: 0, identificacion: '', nombres: '', apellido1: '', apellido2: '', empresa: '', cargo: '', telefono: '', celular: '', departamento_id: null, ciudad_id: null, correo: '', pais_id: 156, direccion: null, tipopersona_id: null, tipousuario_id: null, sexo_id: null, lgbtiq_id: 251, especial_id: 271 }

  }),
  props: ['dialog', 'colores', 'rules', 'items', 'registro', 'gescontacto'],
  created () {
    this.llenarDatos()
  },
  methods: {
    cerrarVenta () {
      this.$emit('closed_asegurado')
    },
    actualizarDatosAsegurado () {
      this.rec_update_asegurado(this.contacto)
    },
    blur_identificacion (pIdentificacion) {
      this.rec_contacto_por_identificacion(pIdentificacion)
    },
    input_pais (pPaisId) {

    },
    input_departamento (pDepartamentoId) {
      this.contacto.ciudad_id = null
      this.rec_departamento_por_id(pDepartamentoId)
    },
    llenarDatos () {
      this.rec_departamento_por_id(this.gescontacto.departamento_id)
      this.contacto.gestion_id = this.gescontacto.gestion_id
      this.contacto.identificacion = this.gescontacto.identificacion
      this.contacto.documento_id = this.gescontacto.documento_id
      this.contacto.nombres = this.gescontacto.nombres
      this.contacto.apellido1 = this.gescontacto.apellido1
      this.contacto.apellido2 = this.gescontacto.apellido2
      this.contacto.cargo = this.gescontacto.cargo
      this.contacto.empresa = this.gescontacto.empresa
      this.contacto.telefono = this.gescontacto.telefono
      this.contacto.celular = this.gescontacto.celular
      this.contacto.direccion = this.gescontacto.direccion
      this.contacto.pais_id = this.gescontacto.pais_id
      this.contacto.departamento_id = this.gescontacto.departamento_id
      this.contacto.ciudad_id = this.gescontacto.ciudad_id
      this.contacto.correo = this.gescontacto.correo
      this.contacto.tipopersona_id = this.gescontacto.tipopersona_id
      this.contacto.tipousuario_id = this.gescontacto.tipousuario_id
      this.contacto.sexo_id = this.gescontacto.sexo_id
      this.contacto.lgbtiq_id = this.gescontacto.lgbtiq_id
      this.contacto.especial_id = this.gescontacto.especial_id
    },
    actualizarQR () {
      this.$emit('actualizar_QR')
    },
    changeTipoDocumento () {
      (this.contacto.documento_id === 244 || this.contacto.documento_id === 91) ? this.contacto.tipopersona_id = 234 : this.contacto.tipopersona_id = 232
    },
    isNumber (evt) {
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
