import axios from 'axios'
export default {
  methods: {
    rec_listados_tipologias (pPrincipalId) {
      const urlroute = this.$store.getters.getUrl + 'listado_principal/' + pPrincipalId
      this.loadingTipologia = true
      this.items_tipologias = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.items_tipologias = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingTipologia = false
      })
    }

  }
}
