<template>
<div style="width:100%">
  <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card  :color="colores.fondo">
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>ESTADISTICAS</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <estadisticas :session="session" :colores="colores" :rules="rules"></estadisticas>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import estadisticas from '@/components/estadisticas/estadisticasComponent.vue'
export default {
  name: 'dialogoEstadisticasComponent',
  components: {
    estadisticas
  },
  props: ['dialog', 'session', 'colores', 'rules'],
  data: () => ({

  }),
  methods: {
    cerrarVenta () {
      this.$emit('closed_estadisticas')
    }
  }
}
</script>
