<template>
<div style="width:100%">
  <v-card>
      <v-toolbar :color="colores.primario" dense dark>
      <span style="font-size:20px"><b>DETALLES QUEJA O RECLAMO</b></span>
      <v-spacer></v-spacer>
      <!-- <v-btn v-if="session.area[0].area_id !== 15" @click="openDialogEditPqr" icon color="white"><v-icon>mdi-pencil</v-icon></v-btn> -->
      <v-btn v-if="session.area[0].area_id === 14 || session.area[0].area_id === 1 && registro.subestado_id !== 4 && registro.subestado_id !== 7" @click="openDialogEditPqr" icon color="white"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'INSTANCIA DE RECEPCIÓN'" :obj="registro.instancia"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'QUEJA EXPRESS'" :obj="registro.quejaexpress"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'TUTELA'" :obj="registro.tutela"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'ENTE DE CONTROL'" :obj="registro.entecontrol"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'RÉPLICA'" :obj="registro.replica"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'ARGUMENTO RÉPLICA'" :descripcion="registro.argumento_replica"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="12" class="pr-1">
            <texto3 :colores="colores" :titulo="'DESISTIMIENTO'" :obj="registro.desistimiento"></texto3>
          </v-col>
        </v-row>
          <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'ADMISIÓN'" :obj="registro.admision"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'PRODUCTO DIGITAL'" :obj="registro.productodigital"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'ACEPTACIÓN'" :obj="registro.aceptacion"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'RECTIFICACIÓN'" :obj="registro.rectificacion"></texto3>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1"  v-if="registro.gesadicional">
            <texto2 v-if="prorroga_id == 251" :colores="colores" :titulo="'PRÓRROGA'" :descripcion="'NO'"></texto2>
            <texto2 v-if="prorroga_id == 250" :colores="colores" :titulo="'PRÓRROGA'" :descripcion="'SI'"></texto2>
          </v-col>
          <v-col v-else cols="12" md="6" class="pr-1">
            <texto3 v-if="prorroga_id == 251" :colores="colores" :titulo="'PRÓRROGA'" :obj="registro.prorroga"></texto3>
            <texto2 v-if="prorroga_id == 250" :colores="colores" :titulo="'PRÓRROGA'" :descripcion="'SI'"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto3 :colores="colores" :titulo="'MARCACION'" :obj="registro.marcacion"></texto3>
          </v-col>
        </v-row>
        <br>
        <v-list dense>
          <div v-for="(item, index) in registro.gesarchivos" :key="index">
            <verArchivos :colores="colores" :tipo="'GESTION'" :item="item"></verArchivos>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-card-text>
  </v-card>
  <edicionPqr :items="items" :rules="rules" :registro="registro"  :dialog="dialogPqr" :colores="colores" @closed_pqr="cerrar_Pqr" @actualizar_QR="update_QR"></edicionPqr>
</div>
</template>

<script>
import verArchivos from '@/components/widgets/ver_archivosComponent.vue'
import texto2 from '@/components/widgets/text2Component.vue'
import texto3 from '@/components/widgets/text3Component.vue'
import recFunciones from '@/js/rec_funciones.js'
import edicionPqr from './edicion_pqrComponent.vue'

export default {
  name: 'bandejaOutboundComponent',
  components: {
    verArchivos,
    texto2,
    texto3,
    edicionPqr
  },
  mixins: [recFunciones],
  props: ['colores', 'registro', 'rules', 'items', 'session', 'prorroga_id'],
  data: () => ({
    dialogPqr: false
  }),
  created () {

  },
  methods: {
    openDialogEditPqr () {
      this.dialogPqr = true
    },
    cerrar_Pqr () {
      this.dialogPqr = false
    },
    update_QR () {
      this.$emit('refrescar_pqr')
    }
  }
}
</script>
