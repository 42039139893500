<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense>
      <v-toolbar-title>CONTACTO</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
      <v-col cols="12" sm="6">
          <v-text-field value="CODIGO DE LA QUEJA" disabled></v-text-field>
        </v-col>
      <v-col cols="12" sm="6">
          <v-text-field value="ESTADO DE LA QUEJA" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.identificacion" :rules="[rules.requerido]" v-on:blur="blur_identificacion(contacto.identificacion)" :success="isSuccess" maxlength="15" counter="15"  label="IDENTIFICACIÓN *" :color="colores.secundario" type="number" min="0" autocomplete="off">
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular v-if="isloadingIden" size="24" indeterminate></v-progress-circular>
              </v-fade-transition>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete v-on:change="changeTipoDocumento" v-model="contacto.documento_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_documentos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* TIPO DE DOCUMENTO' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.nombres" :rules="[rules.requerido]" label='* NOMBRES' autocomplete="off" :color="colores.secundario" maxlength="40" counter="40"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="pr-1">
            <v-text-field v-model="contacto.apellido1" :rules="[rules.requerido]" label='* PRIMER APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="contacto.apellido2" label='SEGUNDO APELLIDO' autocomplete="off" :color="colores.secundario" maxlength="30" counter="30"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.empresa" label='EMPRESA' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field v-model="contacto.cargo" label='CARGO' autocomplete="off" :color="colores.secundario" maxlength="50" counter="50"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.telefono" label='TELEFONO' @keypress="isNumber($event)" autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.celular" label='CELULAR' @keypress="isNumber($event)"   autocomplete="off" :color="colores.secundario" maxlength="10" counter="10"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" >
          <v-autocomplete v-model="contacto.pais_id"  v-on:input="input_pais(contacto.pais_id)" :loading="loadingForm" :items="items.items_paises" item-text="descripcion" item-value="id" :color="colores.secundario" label='PAÍS' autocomplete="on"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="contacto.departamento_id" :rules="[rules.requerido]" v-on:input="input_departamento(contacto.departamento_id)" :loading="loadingForm" :items="items.items_departamentos" item-text="descripcion" item-value="id" :color="colores.secundario" label='DEPARTAMENTO' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="contacto.ciudad_id" :rules="[rules.requerido]" :loading="loadingCiudad" :items="items_ciudades" item-text="descripcion" item-value="id" :color="colores.secundario" label='CIUDAD' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="contacto.correo" :rules="[rules.requerido, rules.email]" label='* CORREO' autocomplete="off" :color="colores.secundario" maxlength="60" counter="60"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="6">
          <v-text-field v-model="contacto.direccion" label='DIRECCIÓN' autocomplete="off" :color="colores.secundario" maxlength="250" counter="250"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="contacto.especial_id" clearable :loading="loadingForm" :items="items.items_condicion_especial" item-text="descripcion" item-value="id" :color="colores.secundario" label='CONDICIÓN ESPECIAL' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <!-- codigo nuevos -->
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="contacto.sexo_id" clearable :loading="loadingForm" :items="items.items_sexo" item-text="descripcion" item-value="id" :color="colores.secundario" label='SEXO' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="contacto.lgbtiq_id" clearable :loading="loadingForm" :items="items.items_lgbtiq" item-text="descripcion" item-value="id" :color="colores.secundario" label='LGTBIQ' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row><v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete  v-model="contacto.tipousuario_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_usuarios" item-text="descripcion" item-value="id" :color="colores.secundario" label='TIPO DE USUARIO' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-if="contacto.documento_id !== 0" disabled v-model="contacto.tipopersona_id" :loading="loadingForm" :items="items.items_tipo_personas" item-text="descripcion" item-value="id" :color="colores.secundario" label='TIPO DE PERSONA' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
            <v-row no-gutters>
        <v-col cols="12">
          <v-textarea v-model="gestion.descripcion" :rules="[rules.requerido]" label='* DESCRIPCION' :color="colores.secundario" counter rows="7"></v-textarea>
        </v-col>
      </v-row>
      <span class="red--text text-h5">{{ objs.erroresMaximo }}</span>
      <br><br>
      <v-row no-gutters>
          <v-col cols="12">
            <uploadFile :titulo="'ADJUNTAR ARCHIVOS'" :session="session" :keyform="gestion.keyform" :items_archivos="items_archivos" :urlroute="(gestion.retroalimentacion_id === 29 || gestion.retroalimentacion_id === 31)?'upload_cargar_nuevo_sfc': 'upload_cargar_nuevo'" :tipo="'GESTION'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFile>
          </v-col>
      </v-row>
      <span class="red--text text-h3">{{ objs.erroresfiles }}</span>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import uploadFile from '@/components/widgets/uploadComponent.vue'
import recDepartamento from '@/js/rec_departamentos.js'
import recContactos from '@/js/rec_contactos.js'

export default {
  name: 'form_aseguradoComponent',
  components: {
    uploadFile
  },
  mixins: [recDepartamento, recContactos],
  props: ['colores', 'rules', 'items', 'loadingForm', 'contacto', 'objs', 'items_archivos', 'gestion', 'session'],
  data: () => ({
    isSuccess: false,
    isloadingIden: false,
    loadingCiudad: false,
    items_ciudades: []
  }),
  created () {
  },
  methods: {
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    input_pais (pPaisId) {

    },
    input_departamento (pDepartamentoId) {
      this.contacto.ciudad_id = null
      this.rec_departamento_por_id(pDepartamentoId)
    },
    blur_identificacion (pIdentificacion) {
      this.rec_contacto_por_identificacion(pIdentificacion)
    },
    isNumber (evt) {
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    changeTipoDocumento () {
      (this.contacto.documento_id === 244 || this.contacto.documento_id === 91) ? this.contacto.tipopersona_id = 234 : this.contacto.tipopersona_id = 232
    }
  }
}
</script>
