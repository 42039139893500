<template>
  <div style="width:100%">
    <v-container fluid>
        <v-card>
          <v-tabs v-model="tab" :background-color="colores.primario" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">BANDEJA<v-icon>mdi-inbox</v-icon></v-tab>
            <v-tab href="#tab-2">CONFIGURACION<v-icon>mdi-cog-outline</v-icon></v-tab>
            <v-tab href="#tab-3">APLICATIVOS<v-icon>mdi-apps</v-icon></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" :value="'tab-1'">
              <v-card flat><v-card-text>  <bandejegaPqrs :objGlobal="objGlobal" :session="session" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingForm" @new_inbound="nuevo_inbound" @refresh_pqrs="refrescar_pqrs"></bandejegaPqrs>  </v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-2'">
              <v-card flat><v-card-text>  <dialogoConfiguracion></dialogoConfiguracion>  </v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="3" :value="'tab-3'">
              <v-card flat><v-card-text><aplicativos :session="session" :colores="colores" :rules="rules"></aplicativos></v-card-text></v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-card>
      <!-- <chat :myRight="380"></chat> -->
      <!-- <chat :myRight="720"></chat> -->
      <dialogoInbound :session="session" :rules="rules" :items="items" :loadingForm="loadingForm" :colores="colores" :dialogInbound="dialogInbound" :tokenInbound="tokenInbound" @closed_inbound="dialogInbound=false"></dialogoInbound>
    </v-container>
  </div>
</template>

<script>
import bandejegaPqrs from '@/components/bandejas/bandeja_pqrsComponent.vue'
// import chat from '@/components/chats/chatComponent.vue'
import dialogoInbound from '@/components/inbound/dialogoComponent.vue'
import dialogoConfiguracion from '@/components/bandejas/bandeja_configuracionCompont.vue'
import aplicativos from '@/components/aplicativos/aplicativosComponent.vue'

export default {
  name: 'bandejaComponent',
  components: {
    bandejegaPqrs,
    aplicativos,
    dialogoInbound,
    dialogoConfiguracion

  },
  props: ['colores', 'items', 'loadingForm', 'session', 'rules', 'objGlobal'],
  data: () => ({
    tab: null,
    dialogInbound: false,
    tokenInbound: 0
  }),
  methods: {
    nuevo_inbound () {
      this.dialogInbound = true
      this.tokenInbound = this.tokenInbound + 1
    },
    refrescar_pqrs () {
      this.$emit('update_pqrs')
    }

  }
}
</script>
