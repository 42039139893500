<template>
<div style="width:100%">
  <br>
  <buscar :session="session" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingForm"></buscar>
  <br>
  <v-form ref="form">
    <asegurado :objs="objs" :session="session" :gestion="gestion" :contacto="contacto" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingForm" :items_archivos="items_archivos"></asegurado>
    <br>
    <pqr :objs="objs" :session="session" :gestion="gestion" :colores="colores" :rules="rules" :items="items" :loadingForm="loadingForm" :otros="otros" ></pqr>
    <br><br>
    <v-btn rounded @click="validar" block dark>GUARDAR</v-btn>
    <br><br><br><br>
  </v-form>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import buscar from '@/components/inbound/buscarComponent.vue'
import asegurado from '@/components/inbound/form_aseguradoComponent.vue'
import pqr from '@/components/inbound/form_pqrComponent.vue'
import recGestion from '@/js/rec_gestion.js'
import recFunciones from '@/js/rec_funciones.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'bandejaOutboundComponent',
  components: {
    buscar,
    asegurado,
    pqr,
    mensajesSave
  },
  mixins: [recGestion, recFunciones],
  props: ['colores', 'items', 'loadingForm', 'session', 'rules', 'tiempo', 'tokenInbound'],
  data: () => ({
    id: 0,
    objs: { retroalimentacion: null, dias: null, erroresfiles: '', erroresMaximo: '' },
    contacto: { gestion_id: 0, documento_id: 0, identificacion: '', nombres: '', apellido1: '', apellido2: '', empresa: '', cargo: '', telefono: '', celular: '', departamento_id: null, ciudad_id: null, correo: '', pais_id: 156, direccion: null, tipopersona_id: null, tipousuario_id: null, sexo_id: null, lgbtiq_id: 251, especial_id: 271 },
    gestion: { aplicativo_id: 319, keyform: 0, id_usuario: 0, canal_id: 0, retroalimentacion_id: 0, tipologia_id: 0, sucursal_id: 0, proceso_id: 0, ramo_id: 0, descripcion: '', subestado_id: 1, segundos: 0, id_usuariocerro: 0, fechacerrado: null, fechaparacerrar: null, dias: 0, codigo_sfc: '', tutela_id: null, entecontrol_id: null, replica_id: null, argumento_replica: null, desistimiento_id: null, quejaexpress_id: null, instancia_id: null, ptorecepcion_id: null, admision_id: null, productodigital_id: null, aceptacion_id: null, rectificacion_id: null, prorroga_id: null, marcacion_id: null },
    otros: { gestion_id: 0, dias: 0 },
    items_archivos: [],
    items_form: { escalamientos: [] },
    items_gestiones: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_gestion_inicializar_contacto()
        this.rec_gestion_inicializar_pqr()
      }
    }
  },
  created () {

  },
  methods: {
    validar () {
      if (this.$refs.form.validate()) {
        if (this.validar_archivos()) {
          this.dialogo.incompleto = true
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    validar_archivos () {
      this.objs.erroresfiles = ''
      this.objs.erroresMaximo = ''
      let isEstado = false
      if (this.gestion.retroalimentacion_id === 31 || this.gestion.retroalimentacion_id === 29) {
        /*
        if (this.gestion.descripcion.length > 4500) {
          this.objs.erroresMaximo = 'MAXIMO DE CARACTERES ES DE 4500'
          isEstado = true
        }
        */
        let cantidad = 0
        for (let i = 0; i < this.items_archivos.length; i++) {
          if (this.items_archivos[i].estado_id === 1) {
            cantidad = cantidad + 1
          }
        }
        if (!this.rec_function_totaSize(this.items_archivos, 30000000)) {
          // 20000000 MB
          this.objs.erroresfiles = 'ERROR - LOS ARCHIVOS EXCEDE LOS 30 MB'
          isEstado = true
        }
      }
      return isEstado
    },
    enviar_datos () {
      this.rec_gestion_nuevo()
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_nuevo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    }

  }
}
</script>
