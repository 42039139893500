<template>
<div style="width:100%">
<v-container fluid class="pa-8">
<v-card>
  <v-card-text>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="anio" label="AÑO" type="number"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn :loading="loading" :disabled="loading" :color="colores.primario" @click="actualizar"><v-icon color="white">mdi-refresh</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
<br>
<v-card v-if="showAnual">
  <v-card-text>
    <v-row v-if="objEstadistica">
      <v-col cols="12" md="6"  v-for="(mes, index) in objEstadistica.meses" :key="index">
        <anualComponent :anio="anio" :mes="mes" :index="index" :objEstadistica="objEstadistica" :colores="colores" @chart_mes="estadistica_mes" ></anualComponent>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
<br>
<v-card v-if="showMes">
  <v-card-text>
    <v-row>
      <v-col cols="12"> <mesComponent v-if="objMes" :anio="anio" :selMes="selMes" :objMes="objMes" :session="session" :colores="colores"></mesComponent> </v-col>
    </v-row>
  </v-card-text>
</v-card>

<br>

</v-container>
</div>
</template>
<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'
import anualComponent from '@/components/estadisticas/anualComponent.vue'
import mesComponent from '@/components/estadisticas/mesComponent.vue'
export default {
  name: 'EstadisticaComponent',
  components: {
    anualComponent,
    mesComponent
  },
  props: ['session', 'colores', 'rules'],
  mixins: [recFunciones, recEstadisticas],
  data: () => ({
    anio: new Date().getFullYear(),
    loading: false,
    loadingMes: false,
    showAnual: false,
    showMes: false,
    objEstadistica: null,
    objMes: null,
    selMes: null
  }), // FIN DE DATA
  created () {
  }, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    actualizar () {
      this.rec_estadisticas_anio(this.anio)
    },
    estadistica_mes (pAnio, pMes) {
      this.selMes = pMes
      this.rec_estadisticas_mes(pAnio, pMes.numero)
    }

  } // FIN DE METODOS
}
</script>
