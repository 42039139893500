<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense>
          <v-toolbar-title>HISTORIAL</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <template>
        <div v-for="(item, index) in registro.geslogs" :key="index">
          <v-row>
            <v-col cols="12" md="4">
              <v-icon>mdi-calendar</v-icon>{{ rec_function_fecha_colombia(item.created_at) }}
            </v-col>
            <v-col cols="12" md="4" class="text-center">
              <v-chip>{{ item.subestado.descripcion }}</v-chip>
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-icon>mdi-account</v-icon>{{ item.usuario.nombre_usuario  + ' ' + item.usuario.apellido_usuario }}
            </v-col>
          </v-row>
          <v-container fluid>
            <span class="text-justify">{{ item.descripcion }}</span>
          </v-container>
          <v-container fluid v-if="item.geslogcliente">
            <span class="text-justify"><b>CORREO: </b>{{ item.geslogcliente.correo }}</span>
          </v-container>
          <v-container fluid>
            <br>
            <b>ESCALAMIENTOS</b>
            <v-row no-gutters>
                <v-col v-for="(escalar, i) in item.escalamientos" cols="12" md="4" class="pt-1 pb-1" :key="i">
                  <v-chip>{{ escalar.user.email }}</v-chip>
                </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <br>
            <v-list dense>
              <div v-for="(archivo, i) in item.geslogarchivos" :key="i">
                <verArchivos :colores="colores" :tipo="'LOG'" :item="archivo"></verArchivos>
                <v-divider></v-divider>
              </div>
            </v-list>
          </v-container>
          <v-divider></v-divider>
          <br>
        </div>
      </template>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import verArchivos from '@/components/widgets/ver_archivosComponent.vue'
export default {
  name: 'lectura_logsComponent',
  components: {
    verArchivos
  },
  mixins: [recFunciones],
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }
}
</script>
