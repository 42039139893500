<template>
<div style="width:100%">
  <v-card>
      <v-toolbar dense :color="colores.primario" dark>
      <span style="font-size:20px"><b>DATOS DEL REQUERIMIENTO</b></span>
       <v-spacer></v-spacer>
      <v-btn v-if="session.area[0].area_id === 14 || session.area[0].area_id === 1 && registro.subestado_id !== 4 && registro.subestado_id !== 7 " @click="openDialogEditHeder" icon color="white"><v-icon>mdi-pencil</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto3 :colores="colores" :titulo="'PUNTO DE RECEPCIÓN'" :obj="registro.ptorecepcion"></texto3>
          </v-col>
          <v-col cols="12" md="6">
            <!-- <texto2 :colores="colores" :titulo="'SUCURSAL'" :descripcion="rec_function_fecha_colombia(registro.created_at)"></texto2> -->
            <texto2 v-if="registro.sucursal !== null" :colores="colores" :titulo="'SUCURSAL'" :descripcion="registro.sucursal.descripcion"></texto2>
            <texto2 v-else :colores="colores" :titulo="'SUCURSAL'" :descripcion="''"></texto2>

          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'RAMO/PRODUCTO'" :descripcion="registro.ramo.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 v-if="registro.proceso !== null" :colores="colores" :titulo="'PROCESO'" :descripcion="registro.proceso.descripcion"></texto2>
            <texto2 v-else :colores="colores" :titulo="'PROCESO'" :descripcion="''"></texto2>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'RETROALIMENTACION'" :descripcion="registro.retroalimentacion.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 v-if="registro.tipologia !== null" :colores="colores" :titulo="'TIPOLOGIA'" :descripcion="registro.tipologia.descripcion"></texto2>
            <texto2 v-else :colores="colores" :titulo="'TIPOLOGIA'" :descripcion="''"></texto2>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
  </v-card>
    <edicionHeader :rules="rules" :items="items"  :dialog="dialogHeader" :colores="colores" :registro="registro" @closed_header="cerrar_header" @actualizar_QR="update_QR"></edicionHeader>
</div>
</template>

<script>
import texto3 from '@/components/widgets/text3Component.vue'
import texto2 from '@/components/widgets/text2Component.vue'
import recFunciones from '@/js/rec_funciones.js'
import edicionHeader from './edicion_headerComponent.vue'
export default {
  name: 'lectura_headerComponent.vue',
  components: {
    texto2,
    texto3,
    edicionHeader
  },
  mixins: [recFunciones],
  props: ['colores', 'registro', 'rules', 'items', 'session'],
  data: () => ({
    dialogHeader: false
  }),
  created () {
    // console.log(this.registro)
  },
  methods: {
    openDialogEditHeder () {
      this.dialogHeader = true
    },
    cerrar_header () {
      this.dialogHeader = false
    },
    update_QR () {
      this.$emit('refrescar_pqr')
    }
  }
}
</script>
