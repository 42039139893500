<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <v-btn :disabled="validarCantidad()"  @click.native="onFocus"><v-icon>mdi-paperclip</v-icon>{{ titulo }}</v-btn>
    </v-col>
    <br>
    <v-col cols="6" align-self="center" class="text-center" v-if="progress>0">
      <v-progress-linear v-model="progress" color="blue darken-3"></v-progress-linear>
      {{ progress }}%
    </v-col>
  </v-row>
  <v-alert v-if="istype" :value="true" type="error">ERROR - LOS TIPOS DE ARCHIVOS AUTORIZADOS pdf, jpg, jpeg, png, mp4, doc, docx, xls, xlsx, bmp, mp3, msg</v-alert>
  <br>
  <v-list>
    <template v-for="(item, index) in items_archivos">
    <div :key="index" v-if="item.estado_id === 1 && item.tipo==tipoAnexo">
        <v-list-item>
            <v-list-item-content>
              <v-list-item-title> {{ item.original }} </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>{{ rec_funciones_bytesToSize(item.tamanio) }}</v-list-item-action-text>
              <v-row no-gutters>
                <v-col cols="12" md="6" align-self="center">
                  <v-icon :color="colores.green">mdi-check-bold</v-icon>
                </v-col>
                <v-col cols="12" md="6" align-self="center">
                  <v-btn @click="eliminar(item.id, index)" icon><v-icon :color="colores.red">mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
    </div>
    </template>
  </v-list>

<!--
  <v-flex xs6 v-if="false">
    <input style="display:none" type="file" :multiple="false" ref="fileInput" @change="onFileChange">
    <v-text-field single-line v-model="filename" ref="fileTextField" :disabled="true" v-if="false"></v-text-field>
    <v-btn icon @click.native="cancelar"><v-icon color="pink" dark>mdi-restore</v-icon></v-btn>
    <v-btn icon @click.native="onFocus"><v-icon color="blue darken-3" dark>mdi-camera</v-icon></v-btn>
  </v-flex>
</v-layout>
-->
<input style="display:none" type="file" :multiple="true" ref="fileInput" @change="onFileChange">
<v-text-field single-line v-model="filename" ref="fileTextField" :disabled="true" v-if="false"></v-text-field>

</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import recUpload from '@/js/rec_upload.js'
import axios from 'axios'
export default {
  name: 'uploadComponent',
  props: ['tipo', 'session', 'items_archivos', 'urlroute', 'keyform', 'colores', 'titulo', 'tipoAnexo'],
  mixins: [recFunciones, recUpload],
  data () {
    return {
      filename: '',
      progress: '0',
      files: [],
      iseliminar: false,
      loadingEliminar: false,
      istype: false
    }
  },
  created () {
  },
  watch: {
  },
  methods: {
    eliminar (pId, i) {
      this.progress = 0
      if (this.tipo === 'LOG') {
        this.rec_upload_log(pId, i)
      } else if (this.tipo === 'CIERRE') {
        this.rec_upload_cierre(pId, i)
      } else {
        this.rec_upload_gestion(pId, i)
      }
    },
    onFocus () {
      this.$refs.fileInput.click()
    },
    onFileChange ($event) {
      const files = $event.target.files || $event.dataTransfer.files
      this.filename = files[0].name
      this.files = files
      this.CargarFiles()
    },
    validarCantidad () {
      let isVisible = false
      for (let i = 0; i < this.items_archivos.length; i++) {
        console.log(this.items_archivos[i])
        if (this.items_archivos[i].estado_id === 1 && this.tipoAnexo === 1 && this.items_archivos[i].tipo === '1' && this.items_archivos[i].sfc === 0) {
          isVisible = true
        }
      }
      return isVisible
    },
    CargarFiles () {
      var filedata = new FormData()
      for (var i = 0; i < this.files.length; i++) {
        filedata.append('files[]', this.files[i])
      }
      filedata.append('usuario_id', this.session.id)
      filedata.append('keyform', this.keyform)
      filedata.append('tipoAnexo', this.tipoAnexo)
      this.iseliminar = false
      this.progress = 0
      var urlroute = this.$store.getters.getUrl + this.urlroute
      axios({
        method: 'POST',
        url: urlroute,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.$store.getters.getToken
        },
        // auth: {
        //  username: this.$store.getters.getHeadUsuario,
        //  password: this.$store.getters.getHeadPassword
        // },
        data: filedata,
        onUploadProgress: function (e) {
          if (e.lengthComputable) {
            var percentComplete = e.loaded / e.total
            percentComplete = parseInt(percentComplete * 100)
            this.progress = percentComplete
          }
        }.bind(this)
      }).then(response => {
        this.$emit('refresh_archivos', response.data)
        this.$refs.fileInput.value = ''
      }, error => {
        if (error.response.status === 422) {
          this.istype = true
          console.error(error.response.data.errors)
        } else {
          console.error(error)
        }
      })
    }
  }
}
</script>
